import { min, startOfToday } from 'date-fns'

import { loadInfoModalLastShownDateFromLocalStorage } from './localStorage'

export const REQUIRE_MODAL_SHOWN_BY_DATE = new Date('2023-07-31')

export const mustShowInfoModal = (
  today: Date = startOfToday(),
  infoModalLastShownDate: Date | null = loadInfoModalLastShownDateFromLocalStorage(),
  require_modal_shown_by_date = REQUIRE_MODAL_SHOWN_BY_DATE
) =>
  min([today, require_modal_shown_by_date]) >
  (infoModalLastShownDate ?? new Date(0))
