import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const CreditsModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Erkenning" isOpen={isOpen} handleClose={handleClose}>
      <p className="mt-6 text-sm text-gray-500 dark:text-gray-300">
        🌱 Worteltjie is 'n kloon van die bekende woordraaispel{' '}
        <a
          href="https://speelwortel.co.za"
          className="font-bold underline"
          target="_blank"
          rel="noreferrer"
        >
          Wortel
        </a>
        . Die oplossings is meer alledaagse woorde sodat leerders op
        laerskoolvlak die woorde sal ken. Dis{' '}
        <a
          href="https://github.com/cwackerfuss/react-wordle"
          className="font-bold underline"
          target="_blank"
          rel="noreferrer"
        >
          oopbronsagteware
        </a>{' '}
        met geen waarborge nie.
      </p>
      <p className="mt-6 text-sm text-gray-500 dark:text-gray-300">
        Afrikaanse instellings deur{' '}
        <a
          href="https://github.com/igitur"
          className="font-bold underline"
          target="_blank"
          rel="noreferrer"
        >
          Francois Botha
        </a>
        .
      </p>
      <p className="mt-6 text-sm text-gray-500 dark:text-gray-300">
        🌱 Worteltjie se daaglikse oplossings word deur die outeur{' '}
        <a
          href="https://maroelamedia.co.za/afrikaans/boeke/boek-hoe-leeu-en-muis-maats-geword-het/"
          className="font-bold underline"
          target="_blank"
          rel="noreferrer"
        >
          Annika Heymans
        </a>{' '}
        in stand gehou.
      </p>
      <div className="mt-6 rounded-xl bg-white p-3">
        <a
          href="https://maroelamedia.co.za/afrikaans/boeke/boek-hoe-leeu-en-muis-maats-geword-het/"
          className="font-bold underline"
          target="_blank"
          rel="noreferrer"
        >
          <img src="Annika-Heymans.jpg" alt="Annika Heymans" />
        </a>
        <p className="text-sm italic">Annika Heymans (regs)</p>
      </div>
    </BaseModal>
  )
}
