export const VALID_GUESSES = [
  'aande',
  'aandu',
  'aanhe',
  'aanhu',
  'aanja',
  'aanle',
  'aanry',
  'aansa',
  'aanse',
  'aanyl',
  'aarde',
  'aards',
  'aaron',
  'abada',
  'abaka',
  // 'abdas',
  'abdes',
  'abdis',
  'abdol',
  'abdye',
  'abeel',
  'abies',
  'abjek',
  'ablou',
  'abmho',
  'aboel',
  'abohm',
  'abool',
  'abram',
  'abrin',
  'abrup',
  'abrus',
  'abses',
  'absis',
  'abuis',
  'accra',
  'achor',
  'acuta',
  'adato',
  'adder',
  'addio',
  'adelf',
  'adeps',
  'adhoc',
  'adieu',
  'aditi',
  'admin',
  'adres',
  'adret',
  'adria',
  'adult',
  'aegir',
  'aegis',
  'aegle',
  'afaak',
  'afaat',
  'afara',
  'afarm',
  'afbas',
  'afbel',
  'afbet',
  'afbid',
  'afbie',
  'afbik',
  'afbly',
  'afbot',
  'afbou',
  'afbyt',
  'afdae',
  'afdag',
  'afdak',
  'afdam',
  'afdek',
  'afdig',
  'afdop',
  'afdor',
  'afdra',
  'afdun',
  'afdyk',
  'afeet',
  'afeis',
  'afend',
  'afent',
  'afese',
  'afets',
  'affek',
  'affer',
  'afgee',
  'afgis',
  'afgly',
  'afgod',
  'afhak',
  'afhap',
  'afhef',
  'afheg',
  'afhel',
  'afhok',
  'afhol',
  'afhou',
  'afhys',
  'afjag',
  'afjak',
  'afjou',
  'afkam',
  'afkap',
  'afkis',
  'afkom',
  'afkop',
  'afkou',
  'afkry',
  'afkus',
  'afkyk',
  'aflag',
  'aflak',
  'aflas',
  'aflat',
  'afleg',
  'aflei',
  'aflek',
  'aflig',
  'aflog',
  'aflok',
  'aflos',
  'aflui',
  'aflyn',
  'afmat',
  'afmik',
  'afnek',
  'afnut',
  'afoes',
  'afoog',
  'afoon',
  'afoor',
  'afpak',
  'afpas',
  'afpel',
  'afpen',
  'afpik',
  'afpis',
  'afpyl',
  'afpyn',
  'afrem',
  'afren',
  'afrig',
  'afrit',
  'afrol',
  'afros',
  'afruk',
  'afrye',
  'afrym',
  'afsae',
  'afsak',
  'afset',
  'afsif',
  'afsit',
  'afsku',
  'afsny',
  'afsuf',
  'afsyg',
  'aftak',
  'aftap',
  'aftas',
  'aftel',
  'aftik',
  'aftip',
  'aftob',
  'aftog',
  'afton',
  'aftop',
  'aftou',
  'aftyd',
  'afval',
  'afvat',
  'afvee',
  'afveg',
  'afvel',
  'afvis',
  'afvou',
  'afvra',
  'afvry',
  'afvyl',
  'afwag',
  'afwal',
  'afwas',
  'afwei',
  'afwen',
  'afwin',
  'afwip',
  'afwis',
  'afwit',
  'afwyk',
  'afwys',
  'agaam',
  'agaat',
  'agada',
  'agama',
  'agami',
  'agape',
  'agarm',
  'agate',
  'agaus',
  'agave',
  'agawe',
  'agdik',
  'ageer',
  'agens',
  'agent',
  'aggie',
  'aghok',
  'agies',
  'agile',
  'agone',
  'agora',
  'agste',
  'agtal',
  'agtel',
  'agter',
  'agurk',
  'aguti',
  'aguur',
  'ahooi',
  'ahorn',
  'aidon',
  'ainie',
  'airol',
  'aisis',
  'aitsa',
  'ajoos',
  'ajour',
  'ajuga',
  'ajuin',
  'akant',
  'akene',
  'akens',
  'akkel',
  'akker',
  'akkie',
  'aknee',
  'akoes',
  'akoon',
  'aksep',
  'akses',
  'aksie',
  'aksin',
  'aksis',
  'akson',
  'aktes',
  'akton',
  'akute',
  'akuut',
  'alane',
  'alant',
  'alarm',
  'albas',
  'albei',
  'albis',
  'album',
  'aldae',
  'aldag',
  'aldan',
  'aldea',
  'aldig',
  'aldol',
  'aldra',
  'aldus',
  'aldyn',
  'aleer',
  'alerm',
  'alfol',
  'algar',
  'alger',
  'algol',
  'algor',
  'alhoe',
  'alias',
  'alibi',
  'alima',
  'alkor',
  'alkyn',
  'allah',
  'allee',
  'aller',
  'alles',
  'allig',
  'almag',
  'almal',
  'alman',
  'almee',
  'alnag',
  'alnus',
  'alpax',
  'alras',
  'alrym',
  'alsem',
  'alsie',
  'alsol',
  'alsyn',
  'altea',
  'altis',
  'altyd',
  'aluin',
  'alula',
  'alvas',
  'alwas',
  'alwee',
  'alwer',
  'alwie',
  'alwyn',
  'alwys',
  'amaas',
  'amant',
  'amara',
  'amasi',
  'amati',
  'ambag',
  'amban',
  'ambei',
  'amber',
  'ambon',
  'ambyl',
  'amebe',
  'amens',
  'ament',
  'amgot',
  'amica',
  'amice',
  'amide',
  'amied',
  'amiel',
  'amien',
  'amies',
  'amikt',
  'amine',
  'amlee',
  'amlie',
  'amman',
  'ammon',
  'amoen',
  'amoom',
  'amora',
  'amorf',
  'amour',
  'ampas',
  'ampel',
  'amper',
  'ampie',
  'ampte',
  'ampul',
  'amrta',
  'anaal',
  'anale',
  'ander',
  'andes',
  'angel',
  'anglo',
  'angst',
  'aniel',
  'anima',
  'animo',
  'ankas',
  'anker',
  'annas',
  'annie',
  'annus',
  'anoda',
  'anode',
  'anoia',
  'anona',
  'anter',
  'antie',
  'antol',
  'anton',
  'anura',
  'anzac',
  'aoris',
  'aorta',
  'aorte',
  'apaat',
  'apart',
  'apate',
  'apeks',
  'apera',
  'apert',
  'apery',
  'aphis',
  'apies',
  'apios',
  'apium',
  'apnee',
  'apoda',
  'apool',
  'appel',
  'april',
  'apsis',
  'aptyt',
  'araba',
  'arain',
  'araka',
  'arbei',
  'arcus',
  'ardea',
  'ardeb',
  'ardil',
  'areas',
  'areca',
  'areka',
  'arena',
  'arend',
  'areng',
  'arfoe',
  'argam',
  'argas',
  'argel',
  'argon',
  'argot',
  'argus',
  'arhat',
  'arias',
  'aried',
  'ariel',
  'aries',
  'arioi',
  'arion',
  'arkal',
  'arkas',
  'arkel',
  'arker',
  'armee',
  'armer',
  'armes',
  'armte',
  'aroei',
  'aroma',
  'aroom',
  'arrag',
  'arrak',
  'arras',
  'arres',
  'arrha',
  'arrie',
  'arrig',
  'arrki',
  'arsis',
  'artar',
  'artel',
  'artis',
  'artse',
  'arums',
  'arupa',
  'asala',
  'asale',
  'asant',
  'asarm',
  'asbad',
  'asbak',
  'asbek',
  'asbes',
  'asbos',
  'asbui',
  'asbus',
  'ascii',
  'ascus',
  'asdag',
  'asdan',
  'asdic',
  'asega',
  'asems',
  'aserb',
  'asgat',
  'ashok',
  'aside',
  'asiel',
  'asies',
  'asige',
  'asine',
  'asing',
  'asjas',
  'askar',
  'askis',
  'askon',
  'askop',
  'askus',
  'aslyf',
  'aslyn',
  'asman',
  'asmis',
  'asnog',
  'asnok',
  'asoka',
  'asook',
  'asoot',
  'asore',
  'asous',
  'aspan',
  'aspek',
  'asper',
  'aspic',
  'aspis',
  'aspot',
  'asput',
  'assai',
  'assef',
  'assem',
  'assez',
  'assie',
  'assur',
  'astap',
  'aster',
  'astra',
  'asura',
  'asurn',
  'asuur',
  'asvel',
  'asvry',
  'aswan',
  'aswip',
  'asyne',
  'ateis',
  'atjar',
  'atlas',
  'atoem',
  'atome',
  'atoom',
  'atopa',
  'atram',
  'attar',
  'attes',
  'attie',
  'attis',
  'attoe',
  'augur',
  'augus',
  'aulos',
  'aurum',
  'avary',
  'avena',
  'avers',
  'avery',
  'avion',
  'awamp',
  'awend',
  'awery',
  'aweto',
  'ayaca',
  'azlon',
  'azote',
  'baaie',
  'baals',
  'baard',
  'baars',
  'babas',
  'babel',
  'baber',
  'babla',
  'baboe',
  'badde',
  'baffa',
  'bafta',
  'bager',
  'bagno',
  'bagyn',
  'bahar',
  'bahoe',
  'baing',
  'bakau',
  'baken',
  'baker',
  'bakke',
  'bakoe',
  'bakwa',
  'baldr',
  'baler',
  'balie',
  'balju',
  'balke',
  'balle',
  'balte',
  'balts',
  'banco',
  'bande',
  'banja',
  'banjo',
  'banke',
  'barak',
  'bardo',
  'baret',
  'barke',
  'barne',
  'baroe',
  'barok',
  'baron',
  'barre',
  'barye',
  'basby',
  'basel',
  'basie',
  'basig',
  'basil',
  'basis',
  'baske',
  'bassa',
  'basse',
  'bassy',
  'basta',
  'bates',
  'batho',
  'batig',
  'batik',
  'batis',
  // 'batja',
  'batos',
  'bawer',
  'bayle',
  'beaai',
  'beaam',
  'beaar',
  'beaux',
  'bebos',
  'bebou',
  'bedag',
  'bedam',
  'bedde',
  'bedek',
  'bedel',
  'bedes',
  'bedil',
  'bedol',
  'bedot',
  'bedou',
  'bedra',
  'bedug',
  'bedui',
  'bedyk',
  'beeld',
  'beemd',
  'befek',
  'befok',
  'begek',
  'begin',
  'begom',
  'begos',
  'begum',
  'begyn',
  'behae',
  'behak',
  'behep',
  'behou',
  'beide',
  'beier',
  'beige',
  'beira',
  'beits',
  'bejag',
  'bejok',
  'bekaf',
  'bekak',
  'bekap',
  'beken',
  'beker',
  'bekis',
  'bekke',
  'bekla',
  'bekom',
  'bekyf',
  'bekyk',
  'belag',
  'belak',
  'belap',
  'belas',
  'belat',
  'belee',
  'beleg',
  'belek',
  'belet',
  'belga',
  'belge',
  'belie',
  'belig',
  'belle',
  'bello',
  'belok',
  'belol',
  'belug',
  'belus',
  'belyf',
  'belym',
  'belyn',
  'beman',
  'bemas',
  'bemat',
  'bemes',
  'bemin',
  'bemis',
  'bemos',
  'benat',
  'bende',
  'bendo',
  'benet',
  'benig',
  'benou',
  'benul',
  'benut',
  'beoog',
  'bepak',
  'bepan',
  'bepek',
  'bepik',
  'bepis',
  'berde',
  'bereg',
  'berei',
  'beret',
  'berge',
  'berib',
  'berig',
  'beril',
  'berin',
  'berke',
  'beroe',
  'berou',
  'berug',
  'berus',
  'berym',
  'beryp',
  'besak',
  'besef',
  'besem',
  'beset',
  'beshu',
  'besie',
  'besig',
  'besin',
  'besit',
  'besny',
  'besog',
  'beson',
  'bespu',
  'beste',
  'besyd',
  'betas',
  'betel',
  'betem',
  'beter',
  'betig',
  'betik',
  'betol',
  'beton',
  'betta',
  'beuel',
  'beuke',
  'beule',
  'beuls',
  'beurs',
  'beurt',
  'beval',
  'bevat',
  'beveg',
  'bevel',
  'bevit',
  'bevog',
  'bevry',
  'bewal',
  'bewas',
  'bewei',
  'bewer',
  'bewoe',
  'bewol',
  'bewus',
  'bewys',
  'bhang',
  'bibit',
  'bidde',
  'bidet',
  'bidri',
  'bieer',
  'biere',
  'biese',
  'bieti',
  'bigot',
  'bijou',
  'bilak',
  // 'bilal',
  'bilik',
  'biner',
  'bingo',
  'binne',
  'biont',
  'bioom',
  'biose',
  'biota',
  'biped',
  'bisam',
  'bisar',
  'bisoe',
  'bison',
  'bissa',
  'bisse',
  'bivak',
  'bizar',
  'blaai',
  'blaak',
  'blaam',
  'blaar',
  'blaas',
  'blank',
  'blaps',
  'blare',
  'blase',
  'bleek',
  'bleik',
  'blerk',
  'blief',
  'bliep',
  'blimp',
  'blind',
  'bling',
  'blink',
  'blits',
  'bloed',
  'bloei',
  'bloek',
  'bloem',
  'bloes',
  'blond',
  'bloos',
  'bloot',
  'blote',
  'bloue',
  'blues',
  'blyer',
  'blyke',
  'boaan',
  'boarm',
  'bobed',
  'bobil',
  'bobly',
  'bobou',
  'bobyn',
  'bocca',
  'boche',
  'bodal',
  'bodek',
  'bodem',
  'bodes',
  'boeba',
  'boegi',
  'boeie',
  // 'boeja',
  'boeke',
  'boeks',
  'boela',
  'boems',
  'boent',
  'boere',
  'boers',
  'boert',
  'boeta',
  'boete',
  'boewe',
  'boffa',
  'bogie',
  'bogis',
  'bogom',
  'bogte',
  'bohou',
  'bojan',
  'bojas',
  'bokap',
  'bokas',
  'bokjy',
  'bokke',
  'bokom',
  'bokop',
  'bokry',
  'bokse',
  'bokwa',
  'bolae',
  'bolas',
  'bolid',
  'bolig',
  'bolip',
  'bolit',
  'bolla',
  'bolle',
  'bolug',
  'bolus',
  'bolyf',
  'bolyn',
  'boman',
  'bomme',
  'bomou',
  'bonde',
  'bonek',
  'bongo',
  'bonne',
  'bonte',
  'bonus',
  'booor',
  'boord',
  'boort',
  'boots',
  'bopad',
  'bopan',
  'borat',
  'borde',
  'boreh',
  'borge',
  'borok',
  'boron',
  'borug',
  'bosak',
  'bosem',
  'boser',
  'boson',
  'bosse',
  'bosui',
  'bosvy',
  'botaf',
  'boter',
  'botte',
  'boude',
  'bouer',
  'boute',
  'bovag',
  'bovak',
  'bovas',
  'bovis',
  'bowal',
  'bowas',
  'bowyk',
  'braaf',
  'braai',
  'braak',
  'braam',
  'braga',
  'bragi',
  'brame',
  'brand',
  'bravo',
  'brawe',
  'breed',
  'breek',
  'breer',
  'brein',
  'brems',
  'breuk',
  'breve',
  'brief',
  'briek',
  'bries',
  'bring',
  'brits',
  'briza',
  'brode',
  'broed',
  'broei',
  'broek',
  'broer',
  'broes',
  'broms',
  'bronn',
  'brons',
  'brood',
  'broom',
  'broos',
  'brose',
  'bruid',
  'bruik',
  'bruin',
  'bruis',
  'brusk',
  'brute',
  'bruto',
  'bruut',
  'bryer',
  'buaza',
  'bugel',
  'buier',
  'buiig',
  'buike',
  'buise',
  'buite',
  'bulle',
  'bulos',
  'bulte',
  'bunga',
  'buran',
  'burat',
  'buret',
  'burge',
  'burin',
  'bursa',
  'buryn',
  'busry',
  'busse',
  'buurt',
  'buust',
  'buxus',
  'bybal',
  'bybel',
  'bybie',
  'bybly',
  'bybol',
  'bybou',
  'bydak',
  'bydam',
  'bydra',
  'byeen',
  'byery',
  'bygee',
  'bygif',
  'bygly',
  'byhou',
  'byjok',
  'bykap',
  'bykok',
  'bykom',
  'bykos',
  'bykry',
  'bylae',
  'bylas',
  'bylig',
  'bylyn',
  'bymot',
  'bynes',
  'byoog',
  'bypad',
  'bypak',
  'bypas',
  'bypos',
  'byrol',
  'bysee',
  'byset',
  'bysin',
  'bysit',
  'bysny',
  'bysom',
  'byson',
  'bysyn',
  'bytak',
  'bytel',
  'byter',
  'bytog',
  'byvak',
  'byval',
  'byvul',
  'byvyl',
  'bywas',
  'byweg',
  'bywen',
  'bywet',
  'bywin',
  'bywit',
  'bywyf',
  'cache',
  'cadia',
  'cairn',
  'calla',
  'calyx',
  'campo',
  'canis',
  'canna',
  'canto',
  'capra',
  'capri',
  'caput',
  'caret',
  'carex',
  'carob',
  'carpe',
  'carta',
  'carte',
  'carum',
  'carus',
  'carya',
  'casco',
  'cassa',
  'casus',
  'catha',
  'cauda',
  'causa',
  'cavia',
  'cebus',
  'ceiba',
  'cella',
  'cello',
  'celsi',
  'cenia',
  'cento',
  'cerci',
  'ceres',
  'ceses',
  'cetus',
  'chabi',
  'chabo',
  'chaos',
  'chara',
  'chela',
  'chemo',
  'chert',
  'chiem',
  'chili',
  'china',
  'chino',
  'chrie',
  'chufa',
  'cicer',
  'cilia',
  'cimex',
  'circa',
  'circe',
  'cisis',
  'cista',
  'claro',
  'clava',
  'cocos',
  'codex',
  'codon',
  'coeur',
  'colla',
  'collo',
  'colon',
  'comes',
  'comma',
  'comte',
  'conga',
  'conte',
  'conto',
  'conus',
  'copla',
  'coqui',
  'coram',
  'corda',
  'corde',
  'coria',
  'corno',
  'cornu',
  'corps',
  'corsa',
  'corso',
  'cosec',
  'costa',
  'coups',
  'covid',
  'coypu',
  'credo',
  'croma',
  'culex',
  'culpa',
  'curia',
  'curie',
  'cutis',
  'cycas',
  'cydia',
  'daags',
  'dabee',
  'dacca',
  'dacha',
  'dadap',
  'dadel',
  'dader',
  'dadig',
  'dager',
  'dagga',
  'dagha',
  'dagie',
  'dagob',
  'dagse',
  'dagsy',
  'daisy',
  'dakke',
  'dalem',
  'dalet',
  'dalks',
  'dalla',
  'damar',
  'damas',
  'damba',
  'dambo',
  'dames',
  'damme',
  'dampe',
  'dandy',
  'danig',
  'danke',
  'danse',
  'danta',
  'danza',
  'darem',
  'darra',
  'darre',
  'dashi',
  'dasse',
  'datum',
  'davib',
  'davit',
  'daweb',
  'dawee',
  'dawer',
  'dawib',
  'dawid',
  'dawie',
  'dawip',
  'debat',
  'debet',
  'debir',
  'decor',
  'deder',
  'deels',
  'deens',
  'deern',
  'defek',
  'degel',
  'degen',
  'degie',
  'deins',
  'dekal',
  'deken',
  'dekke',
  'dekor',
  'deleb',
  'deler',
  'delft',
  'delhi',
  'delik',
  'della',
  'delle',
  'dello',
  'delta',
  'delwe',
  'demas',
  'demon',
  'denim',
  'denke',
  'denne',
  'depot',
  'derby',
  'derde',
  'derma',
  'derms',
  'derre',
  'derwe',
  'deser',
  'deses',
  'dessa',
  'detto',
  'detur',
  'deure',
  'dewyl',
  'dhikr',
  'diade',
  'diana',
  'diarg',
  'dibio',
  'dicta',
  'dieen',
  'dieet',
  'diens',
  'diepe',
  'diere',
  'diete',
  'diets',
  'diewe',
  'digby',
  'digte',
  'dikdy',
  'dikir',
  'dikna',
  'dikte',
  'dille',
  'dinar',
  'dinas',
  'dinee',
  'dinge',
  'dingo',
  'dinke',
  'dinol',
  'diode',
  'diose',
  'direk',
  'disas',
  'disko',
  'disse',
  'ditsy',
  'ditto',
  'divan',
  'diwan',
  'djami',
  'djati',
  'djawi',
  'djina',
  'djinn',
  'dobba',
  'dobra',
  'doder',
  'dodes',
  'doeka',
  'doeke',
  'doeks',
  'doele',
  'doema',
  'doemf',
  'doems',
  'doene',
  'doepa',
  'doeps',
  'dogma',
  'dokka',
  'dokke',
  'dolce',
  'dolik',
  'dolke',
  // 'dolla',
  'dolle',
  'dolly',
  'dolor',
  'dolos',
  'dolus',
  'dolwe',
  'domba',
  'domme',
  'domra',
  'domus',
  'donau',
  'donga',
  'donna',
  'donor',
  'donse',
  'donum',
  'doods',
  'dooie',
  'doper',
  'doppe',
  'dorab',
  'dorie',
  'dorpe',
  'dorps',
  'dorre',
  'dorte',
  'dorvy',
  'dosie',
  'dosis',
  'dosyn',
  'dotte',
  'douig',
  'doula',
  'dower',
  'dowes',
  'dowwe',
  'doyen',
  'draad',
  'draag',
  'draai',
  'draak',
  'draal',
  'drade',
  'draer',
  'drake',
  'drama',
  'drang',
  'drank',
  'drape',
  'drawe',
  'dreef',
  'dreig',
  'drein',
  'dreks',
  'drenk',
  'drens',
  'drent',
  'dreun',
  'drewe',
  'dries',
  'dring',
  'drink',
  'droef',
  'droer',
  'droes',
  'droit',
  'drome',
  'dronk',
  'droog',
  'droom',
  'drots',
  'drude',
  'druif',
  'druil',
  'druip',
  'druis',
  'druks',
  'drupa',
  'drywe',
  'duaal',
  'dubio',
  'dugte',
  'duike',
  'duime',
  'duine',
  'duite',
  'duits',
  'duiwe',
  'dulia',
  'dumba',
  'dumka',
  'dunte',
  'duolo',
  'duool',
  'dupes',
  'duplo',
  'duria',
  'durra',
  'durum',
  'durus',
  'durwe',
  'duske',
  'duuts',
  'duvet',
  'duwel',
  'dwaal',
  'dwaas',
  'dwaba',
  'dwang',
  'dwars',
  'dwase',
  'dweep',
  'dweil',
  'dwelm',
  'dwerg',
  'dwing',
  'dyaar',
  'dyaus',
  'dylap',
  'dyoba',
  'eagle',
  'ebola',
  'echea',
  'edeem',
  'edele',
  'edeme',
  'ediel',
  'edita',
  'eedle',
  'eelte',
  'eende',
  'eenre',
  'eeral',
  'eetwa',
  'eeuig',
  'eeuse',
  'efebe',
  'efeet',
  'efese',
  'effek',
  'effen',
  'efoor',
  'efsee',
  'egaal',
  'egger',
  'eggos',
  'egois',
  'egpen',
  'egret',
  'egter',
  'egtig',
  'eider',
  'eidon',
  'eidos',
  'eiers',
  'eikel',
  'eikon',
  'einde',
  'eirie',
  'eisel',
  'eiser',
  'eiste',
  'eivol',
  'eiwit',
  'ejeka',
  'ekade',
  'ekese',
  'ekori',
  'eksak',
  'ekses',
  'ekspo',
  'eksug',
  'eland',
  'elemi',
  'elfde',
  'elgin',
  'elisa',
  'elite',
  'elker',
  'ellie',
  'eloge',
  'elses',
  'elsie',
  'elvis',
  'email',
  'emirs',
  'emkol',
  'emlyn',
  'emmer',
  'emoes',
  'emoji',
  'enema',
  'eners',
  'enfin',
  'engel',
  'enger',
  'engte',
  'enige',
  'enine',
  'enjin',
  'enkel',
  'enlyn',
  'ennui',
  'enorm',
  'enter',
  'entre',
  'epiek',
  'epies',
  'epode',
  'erbia',
  'ereal',
  'erens',
  'erger',
  'ergot',
  'erika',
  'erken',
  'erker',
  'eroeb',
  'error',
  'ertse',
  'escom',
  'esels',
  'esker',
  'eslas',
  'essai',
  'essay',
  'essie',
  'ester',
  'etaan',
  'etage',
  'eteen',
  'etend',
  'eters',
  'etery',
  'ethou',
  'etide',
  'etiek',
  'etiel',
  'eties',
  'etika',
  'etine',
  'etser',
  'etter',
  'etude',
  'eulan',
  'eunug',
  'eusol',
  'euwel',
  'evavy',
  'evies',
  'evita',
  'eweas',
  'ewens',
  'eweso',
  'ewige',
  'ewwie',
  'fabel',
  'facie',
  'facta',
  'facto',
  'fagot',
  'fahak',
  'faham',
  'fakir',
  'fakse',
  'faler',
  'falie',
  'falli',
  'fancy',
  'fango',
  'fanon',
  'fanum',
  'fapie',
  'fappa',
  'farad',
  'farao',
  'farce',
  'fasel',
  'fases',
  'faset',
  'fasie',
  'fasor',
  'fasti',
  'fatte',
  'fatum',
  'fauna',
  'faune',
  'faust',
  'favus',
  'feces',
  'fecit',
  'feeks',
  'feile',
  'feite',
  'feler',
  'felle',
  'femel',
  'femme',
  'femur',
  'fenol',
  'feria',
  'ferie',
  'ferme',
  'fermo',
  'feron',
  'feses',
  'fesie',
  'fetus',
  'fewer',
  'fiaal',
  'fibel',
  'fibra',
  'fiche',
  'fichu',
  'fidei',
  'fides',
  'fidji',
  'fiela',
  'fielt',
  'fiere',
  'fiern',
  'fiero',
  'fieta',
  'fiets',
  'fikse',
  'filet',
  'films',
  'filum',
  'fingo',
  'finis',
  'fioel',
  'fiool',
  'firma',
  'fisie',
  'fitis',
  'fitol',
  'fjeld',
  'fjord',
  'flair',
  'flank',
  'flans',
  'flaps',
  'flard',
  'fleem',
  'fleim',
  'flens',
  'flets',
  'fleur',
  'fliek',
  'flink',
  'flint',
  'flirt',
  'flits',
  'floep',
  'floks',
  'flora',
  'flous',
  'fluim',
  'fluit',
  'fluks',
  'fluor',
  'fnuik',
  'fobie',
  'foeja',
  'foena',
  'foepa',
  'fokof',
  'fokop',
  'fokus',
  'folia',
  'folio',
  'fonds',
  'fondu',
  'fonks',
  'fooie',
  'force',
  'forel',
  'forie',
  'forma',
  'forse',
  'forte',
  'forto',
  'forum',
  'forza',
  'fossa',
  'foton',
  'fotos',
  'foute',
  'fovea',
  'foyer',
  'fraai',
  'frank',
  'frans',
  'frase',
  'frats',
  'fraus',
  'frees',
  'freon',
  'frese',
  'freud',
  'freyr',
  'fries',
  'fring',
  'friss',
  'frits',
  'frons',
  'front',
  'fruit',
  'fruta',
  'fudge',
  'fuero',
  'fugue',
  'fuiwe',
  'fundi',
  'funes',
  'fungi',
  'fuoco',
  'furca',
  'furia',
  'furie',
  'furil',
  'furor',
  'fusie',
  'futon',
  'fymel',
  'fyneg',
  'fyner',
  'fynte',
  'gaans',
  'gaard',
  'gaats',
  'gabba',
  'gades',
  'gagga',
  'galas',
  'galea',
  'galei',
  'galge',
  'galla',
  'galle',
  'galme',
  'galms',
  'galon',
  'galop',
  'gamba',
  'gamet',
  'gamis',
  'gamma',
  'gamme',
  'gange',
  'ganna',
  'ganse',
  'gaper',
  'gapoe',
  'garbo',
  'garde',
  'gares',
  'garra',
  'gasal',
  'gasel',
  'gaset',
  'gasie',
  'gasig',
  'gasse',
  'gaste',
  'gaswa',
  // 'gatta',
  'gatte',
  'gauge',
  'gauss',
  'gawes',
  'gawie',
  'gayle',
  'geaap',
  'geaar',
  'gearm',
  'gebad',
  'gebak',
  'gebal',
  'geban',
  'gebed',
  'gebef',
  'gebek',
  'gebel',
  'gebid',
  'gebie',
  'gebit',
  'gebly',
  'gebod',
  'geboe',
  'gebol',
  'gebom',
  'gebos',
  'gebot',
  'gebou',
  'gebry',
  'gebuk',
  'gebyt',
  'gedag',
  'gedam',
  'gedas',
  'gedek',
  'gedig',
  'gedip',
  'gedoe',
  'gedog',
  'gedok',
  'gedol',
  'gedop',
  'gedor',
  'gedos',
  'gedou',
  'gedra',
  'gedug',
  'gedui',
  'gedun',
  'gedut',
  'geeer',
  'geeet',
  'geefs',
  'geeis',
  'geent',
  'geerf',
  'geerg',
  'geery',
  'geets',
  'gefop',
  'gegas',
  'gegee',
  'gegil',
  'gegis',
  'geglo',
  'gegly',
  'gegom',
  'gegor',
  'gegru',
  'gegun',
  'gehad',
  'gehak',
  'gehap',
  'gehef',
  'geheg',
  'gehei',
  'gehel',
  'gehik',
  'gehoe',
  'gehok',
  'gehol',
  'gehop',
  'gehor',
  'gehou',
  'gehug',
  'gehul',
  'gehum',
  'gehup',
  'gehyg',
  'gehys',
  'geita',
  'geite',
  'gejae',
  'gejag',
  'gejas',
  'gejil',
  'gejok',
  'gejol',
  'gejou',
  'gejuk',
  'gekam',
  'gekap',
  'gekas',
  'gekef',
  'geken',
  'gekir',
  'gekis',
  'gekke',
  'gekko',
  'gekla',
  'gekog',
  'gekol',
  'gekom',
  'gekop',
  'gekos',
  'gekou',
  'gekry',
  'gekug',
  'gekul',
  'gekyf',
  'gekyk',
  'gekys',
  'gelae',
  'gelag',
  'gelak',
  'gelam',
  'gelap',
  'gelas',
  'gelde',
  'gelee',
  'geleg',
  'gelei',
  'gelek',
  'geler',
  'geles',
  'gelet',
  'gelid',
  'gelig',
  'gelip',
  'gelit',
  'gelob',
  'gelok',
  'gelol',
  'gelos',
  'gelub',
  'gelug',
  'gelui',
  'geluk',
  'gelyk',
  'gelym',
  'gelyn',
  'gelys',
  'gemag',
  'gemak',
  'gemat',
  'gemes',
  'gemik',
  'gemis',
  'gemma',
  'gemme',
  'gemoe',
  'gemok',
  'gemol',
  'gemor',
  'gemos',
  'gemot',
  'gemuf',
  'gemus',
  'gemyn',
  'genee',
  'genek',
  'gener',
  'genes',
  'genet',
  'genie',
  'genog',
  'genom',
  'genos',
  'genot',
  'genre',
  'gents',
  'genus',
  'genut',
  'geode',
  'geoes',
  'geoog',
  'geoor',
  'gepag',
  'gepak',
  'gepap',
  'gepas',
  'gepel',
  'gepes',
  'gepik',
  'gepla',
  'gepoe',
  'gepof',
  'gepor',
  'gepos',
  'gepot',
  'gepuf',
  'geput',
  'gepyl',
  'gepyn',
  'gepyp',
  'geram',
  'gerat',
  'gered',
  'gereg',
  'gerei',
  'gerek',
  'gerem',
  'gerep',
  'gerib',
  'gerif',
  'gerig',
  'geril',
  'gerok',
  'gerol',
  'geros',
  'gerot',
  'gerou',
  'gerub',
  'gerug',
  'gerui',
  'geruk',
  'gerus',
  'gerwe',
  'geryg',
  'gerym',
  'geryp',
  'gerys',
  'gesae',
  'gesag',
  'gesak',
  'gesar',
  'geseg',
  'gesel',
  'geset',
  'gesie',
  'gesif',
  'gesig',
  'gesin',
  'gesis',
  'gesit',
  'gesny',
  'gesog',
  'gespe',
  'gespu',
  'gesta',
  'geste',
  'gestu',
  'gesug',
  'gesus',
  'gesyg',
  'getak',
  'getal',
  'getap',
  'getas',
  'getee',
  'getel',
  'getem',
  'getik',
  'getob',
  'getoe',
  'getok',
  'getol',
  'getop',
  'getou',
  'getug',
  'getum',
  'getye',
  'geuit',
  'geure',
  'geute',
  'geval',
  'gevas',
  'gevat',
  'gevee',
  'geveg',
  'gevel',
  'geves',
  'gevin',
  'gevis',
  'gevit',
  'gevly',
  'gevog',
  'gevou',
  'gevra',
  'gevry',
  'gevul',
  'gevyl',
  'gewae',
  'gewag',
  'gewan',
  'gewas',
  'geweb',
  'gewed',
  'gewei',
  'gewek',
  'gewel',
  'gewen',
  'gewer',
  'gewes',
  'gewet',
  'gewig',
  'gewik',
  'gewil',
  'gewin',
  'gewip',
  'gewir',
  'gewis',
  'gewit',
  'gewoe',
  'gewol',
  'gewyd',
  'gewyk',
  'gewys',
  'gewyt',
  // 'ghaai',
  'ghaan',
  'ghaap',
  'ghaas',
  'ghaba',
  'ghaip',
  'ghala',
  'ghams',
  'ghana',
  'ghape',
  'ghaps',
  'ghara',
  'ghasi',
  'ghawa',
  'gheip',
  'gheis',
  'ghiep',
  'ghier',
  'ghnap',
  'ghnoe',
  'ghnoi',
  'ghoef',
  'ghoei',
  'ghoem',
  'ghoen',
  'ghoer',
  'ghoes',
  'gholf',
  'gholm',
  'ghong',
  'ghoos',
  'ghrep',
  'ghrok',
  'ghrop',
  'ghwab',
  'ghwai',
  'ghwar',
  'ghwel',
  'ghwir',
  'ghwyn',
  'giaur',
  'gibba',
  'gibli',
  'gibus',
  'gidse',
  'giela',
  'giere',
  'giers',
  'gifby',
  'gifte',
  'gifui',
  'gigue',
  'gilde',
  'gille',
  'gimel',
  'ginds',
  'giour',
  'giraf',
  'girts',
  'giste',
  'gitta',
  'giwwe',
  'glaas',
  'glads',
  'glans',
  'glase',
  'gleba',
  'gleuf',
  'glief',
  'glimp',
  'glips',
  'glits',
  'globe',
  'gloea',
  'gloed',
  'gloef',
  'gloei',
  'gloof',
  'glooi',
  'gloop',
  'gloor',
  'gluip',
  'gluma',
  'gluon',
  'gluur',
  'glyer',
  'gnaap',
  'gneis',
  'gnome',
  'gnooi',
  'gnoom',
  'gnose',
  'gobba',
  'godes',
  'godet',
  'godin',
  'godis',
  'goede',
  'goeds',
  'goeie',
  'goena',
  'goeps',
  'goesa',
  'goete',
  'goets',
  'gofer',
  'gogel',
  'gogga',
  'goien',
  'golem',
  'golla',
  'golwe',
  'gombo',
  'gomer',
  'gomma',
  'gonja',
  'gonje',
  'gonna',
  'gonne',
  'gopse',
  'gorak',
  'gorde',
  'goree',
  'gorna',
  'gorra',
  'gotla',
  'gotta',
  'gouda',
  'gouds',
  'gouer',
  'gouig',
  'goute',
  'graad',
  'graaf',
  'graag',
  'graai',
  'graal',
  'graan',
  'graas',
  'graat',
  'graau',
  'grade',
  'grand',
  'grane',
  'grate',
  'grave',
  'grawe',
  'greep',
  'grein',
  'grens',
  'grepe',
  'grief',
  'griek',
  'griel',
  'griep',
  'griet',
  'grime',
  'grind',
  'grint',
  'groef',
  'groei',
  'groen',
  'groep',
  'groet',
  'grond',
  'grons',
  'groos',
  'groot',
  'grote',
  'grout',
  'growe',
  'gruis',
  'gryns',
  'gryse',
  'guaca',
  'guaco',
  'guano',
  'gudok',
  'guida',
  'guite',
  'gukas',
  'gulde',
  'gulpe',
  'gumbo',
  'gumma',
  'gummi',
  'gusla',
  'gusle',
  'gusli',
  'gusto',
  'gutse',
  'gutta',
  'gyrus',
  'gysel',
  'haags',
  'haaie',
  'haaik',
  'haait',
  'haaks',
  'haard',
  'haars',
  'habyt',
  'hadde',
  'hades',
  'haiti',
  'haits',
  'hakea',
  'haker',
  'hakie',
  'hakig',
  'hakke',
  'haler',
  'halie',
  'halle',
  'hallo',
  'halma',
  'halme',
  'halms',
  'halse',
  'halte',
  'halwe',
  'hamba',
  'hamei',
  'hamel',
  'hamer',
  'hamme',
  'hamza',
  'hanau',
  'hande',
  'hange',
  'hanif',
  'hanka',
  'hanou',
  'hanse',
  'haper',
  'happe',
  'haram',
  'harde',
  'harem',
  'harig',
  'harke',
  'harpe',
  'harpy',
  'harse',
  'harta',
  'harte',
  'hasie',
  'hater',
  'hatig',
  'haute',
  'haveg',
  'hawel',
  'hawer',
  'hawes',
  'hawik',
  'hebbe',
  'heden',
  'heeia',
  'heeja',
  'heems',
  'heers',
  'hefas',
  'hefsy',
  'hefte',
  'hefwa',
  'hegsy',
  'hegte',
  'heias',
  'heida',
  'heide',
  'heila',
  'heina',
  'heira',
  'heisa',
  'heita',
  'heits',
  'hekel',
  'hekka',
  'hekke',
  'hekse',
  'helde',
  'heler',
  'heles',
  'helle',
  'helms',
  'helse',
  'helwa',
  'hemde',
  'hemel',
  'hengs',
  'henna',
  'henne',
  'henou',
  'henry',
  'hepar',
  'herba',
  'herde',
  'herfs',
  'herig',
  'herme',
  'hernu',
  'heros',
  'herte',
  'hertz',
  'heryk',
  'heter',
  'hetig',
  'hetse',
  'hetsy',
  'hette',
  'heuel',
  'heupe',
  'hewel',
  'hewer',
  'hewig',
  'hewwe',
  'hiaat',
  'hiade',
  'hiate',
  'hidra',
  'hidro',
  'hiele',
  'hiena',
  'hiert',
  'hiesa',
  'hiets',
  'hille',
  'hilum',
  'hilus',
  'himen',
  'himne',
  'hinde',
  'hings',
  'hiper',
  'hippo',
  'hisop',
  'hitse',
  'hitte',
  'hobos',
  'hoede',
  'hoeit',
  'hoeka',
  'hoeke',
  'hoeks',
  'hoela',
  'hoera',
  'hoere',
  'hoeri',
  'hoese',
  'hoewe',
  'hofie',
  'hogel',
  'hogie',
  'hoija',
  'hokai',
  'hokja',
  'hokke',
  'hokko',
  'hokma',
  'holas',
  'holis',
  'holle',
  'holte',
  'homer',
  'homma',
  'homme',
  'hommo',
  'homoe',
  'homoi',
  'hompe',
  'honde',
  'honds',
  'hongi',
  'honja',
  'honna',
  'honne',
  'honni',
  'honou',
  'honta',
  'hoofs',
  'hoogs',
  'hooke',
  'hoort',
  'hopie',
  'hopsa',
  'horde',
  'horig',
  'horme',
  'horte',
  'hoser',
  'hosie',
  'hotel',
  'hotom',
  'hotos',
  'hotsy',
  'hotte',
  'houer',
  'houri',
  'houte',
  'huaca',
  'hubbe',
  'hudag',
  'huide',
  'huige',
  'huile',
  'huise',
  'hulde',
  'hulle',
  'hulpe',
  'hulse',
  'humor',
  'humus',
  'hunne',
  'hupse',
  'hurke',
  'hurle',
  'husky',
  'husse',
  'hutte',
  'hydra',
  'hymen',
  'hyrax',
  'hyser',
  'ibeks',
  'iboga',
  'ictus',
  'idees',
  'idola',
  'idool',
  'idose',
  'iebie',
  'ieder',
  'ierse',
  'iewer',
  'igloe',
  'ignis',
  'ihram',
  'ikone',
  'ikoon',
  'ilama',
  'ileks',
  'ileum',
  'ileus',
  'ilium',
  'iltis',
  'image',
  'imago',
  'imari',
  'imide',
  'imine',
  'imker',
  'immer',
  'impak',
  'impie',
  'impos',
  'inbak',
  'inbed',
  'inbel',
  'inbly',
  'inbom',
  'inbou',
  'inbuk',
  'inbyt',
  'incus',
  'indam',
  'index',
  'indie',
  'indik',
  'indol',
  'indra',
  'indri',
  'indut',
  'indwe',
  'indyk',
  'ineen',
  'ineng',
  'inent',
  'inert',
  'inets',
  'infra',
  'ingat',
  'ingee',
  'ingly',
  'inham',
  'inhap',
  'inheg',
  'inhei',
  'inhel',
  'inhok',
  'inhol',
  'inhou',
  'inhul',
  'inhyg',
  'inink',
  'inion',
  'injae',
  'inkam',
  'inkap',
  'inkas',
  'inkau',
  'inkom',
  'inkry',
  'inkus',
  'inkyk',
  'inlae',
  'inlas',
  'inleg',
  'inlei',
  'inlek',
  'inlig',
  'inlog',
  'inlok',
  'inlos',
  'inlui',
  'inlyf',
  'inlym',
  'inlyn',
  'inlys',
  'inner',
  'innig',
  'inoes',
  'inose',
  'inpak',
  'inpas',
  'inpik',
  'inpot',
  'inpyl',
  'inrig',
  'inrit',
  'inrol',
  'inruk',
  'inrye',
  'inryg',
  'inryp',
  'insae',
  'insak',
  'insek',
  'insem',
  'inses',
  'inset',
  'insig',
  'insit',
  'insny',
  'insok',
  'insyg',
  'intak',
  'intap',
  'intel',
  'inter',
  'intik',
  'intog',
  'intol',
  'intou',
  'intra',
  'intro',
  'inval',
  'invar',
  'invat',
  'invee',
  'invet',
  'invog',
  'invou',
  'invra',
  'invul',
  'invyl',
  'inwag',
  'inwal',
  'inwas',
  'inwee',
  'inwen',
  'inwig',
  'inwin',
  'inwip',
  'inwyk',
  'ipeka',
  'iraki',
  'irans',
  'irato',
  'irbis',
  'ireen',
  'irene',
  'iroko',
  'iroon',
  'islam',
  'ismes',
  'ismus',
  'issit',
  'istle',
  'items',
  'itjie',
  'ivaol',
  'ivoor',
  'ivore',
  'jaaif',
  'jaart',
  'jabot',
  'jaend',
  'jaens',
  'jaers',
  'jaery',
  'jafel',
  'jaffa',
  'jafta',
  'jagra',
  'jagte',
  'jagwa',
  'jakke',
  'jakob',
  'jalap',
  'jaleo',
  'jambe',
  'janee',
  'janet',
  'janna',
  'janus',
  'japan',
  'japie',
  'japon',
  'jarig',
  'jarra',
  'jasjt',
  'jasse',
  'javal',
  'javel',
  'jawel',
  'jeans',
  'jecur',
  'jeens',
  'jehad',
  'jelab',
  'jelei',
  'jelig',
  'jemel',
  'jemen',
  'jemie',
  'jento',
  'jerga',
  'jesou',
  'jesus',
  'jeton',
  'jette',
  'jeuks',
  'jiems',
  'jieta',
  'jingo',
  'jings',
  'jinne',
  'jirts',
  'jitte',
  'joala',
  'jodel',
  'jodin',
  'joean',
  'joeba',
  'joeks',
  'joepa',
  'joert',
  'joeta',
  'jogga',
  'jogie',
  'jogis',
  'joker',
  'jolig',
  'jolyt',
  'jomps',
  'jonge',
  'jongs',
  'jonke',
  'joods',
  'jools',
  'joost',
  'joppa',
  'jorts',
  'josef',
  'josep',
  'josie',
  'josop',
  'josua',
  'josup',
  'jouer',
  'joule',
  'joune',
  'jubee',
  'jubel',
  'judas',
  'jugum',
  'juins',
  'juits',
  'jukka',
  'jukke',
  'jukos',
  'julie',
  'julle',
  'junie',
  'junne',
  'junod',
  'junta',
  'jurat',
  'jurie',
  'juris',
  'jurke',
  'juste',
  'juvia',
  'kaaie',
  'kaaps',
  'kaard',
  'kaart',
  'kaats',
  'kabaa',
  'kabab',
  'kabam',
  'kabar',
  'kabel',
  'kabep',
  'kabie',
  'kabob',
  'kaboe',
  'kabos',
  'kadel',
  'kader',
  'kades',
  'kadet',
  'kadie',
  'kados',
  'kadot',
  'kafee',
  'kaffa',
  'kafte',
  'kafwa',
  'kagoe',
  'kahle',
  'kaien',
  'kairo',
  'kajak',
  'kajol',
  'kakao',
  'kakap',
  'kakel',
  'kakie',
  'kakka',
  'kakke',
  'kaksa',
  'kakwa',
  'kalam',
  'kaldi',
  'kales',
  'kalie',
  'kalke',
  'kalla',
  'kalme',
  'kalos',
  'kalot',
  'kalpa',
  'kalwe',
  'kamam',
  'kamas',
  'kamba',
  'kambe',
  'kamee',
  'kamel',
  'kamer',
  'kames',
  'kamie',
  'kamik',
  'kamil',
  'kamko',
  'kamma',
  'kamme',
  'kamoe',
  'kamos',
  'kampe',
  'kamso',
  'kamta',
  'kamte',
  'kanas',
  'kanat',
  'kandy',
  'kanet',
  'kanie',
  'kanis',
  'kanja',
  'kanji',
  'kanna',
  'kanne',
  'kanoe',
  'kanol',
  'kanon',
  'kanos',
  'kanse',
  'kante',
  'kanto',
  'kanyn',
  'kaori',
  'kapaf',
  'kapat',
  'kapeg',
  'kapel',
  'kaper',
  'kapet',
  'kapie',
  'kapla',
  'kapok',
  'kapot',
  'kappa',
  'kappe',
  'kapry',
  'kaput',
  'kapwa',
  'karaf',
  'karas',
  'karba',
  'kardo',
  'karea',
  'karee',
  'karet',
  'karga',
  'kargo',
  'karie',
  'karig',
  'karma',
  'karob',
  'karoe',
  'karol',
  'karoo',
  'karos',
  'karpe',
  'karpi',
  'karra',
  'karre',
  'karri',
  'karro',
  'karry',
  'karst',
  'karts',
  'karwy',
  'kasan',
  'kasba',
  'kasie',
  'kasig',
  'kasja',
  'kassa',
  'kasse',
  'kasso',
  'kasta',
  'kaste',
  'kasty',
  'kasus',
  'kaswa',
  'katar',
  'katas',
  'katel',
  'kater',
  'katma',
  'katot',
  'katse',
  'katta',
  'katte',
  'katti',
  'katyf',
  'kauri',
  'kawie',
  'kawil',
  'kawja',
  'kebab',
  'kebie',
  'kebob',
  'keboe',
  'kedif',
  'kedot',
  'kefir',
  'kegel',
  'kehla',
  'keile',
  'keisa',
  'keita',
  'kejol',
  'keker',
  'kelim',
  'kelke',
  'kelos',
  'kemel',
  'kempe',
  'kenaf',
  'kendo',
  'kenet',
  'kenia',
  'kenne',
  'kenta',
  'kents',
  'kepel',
  'keper',
  'kepie',
  'kepse',
  'kerel',
  'keret',
  'kerie',
  'keris',
  'kerke',
  'kerks',
  'kerky',
  'kerme',
  'kerne',
  'kerns',
  'kerse',
  'kerwe',
  'kesie',
  'kessa',
  'ketan',
  'ketel',
  'keten',
  'ketib',
  'ketla',
  'ketol',
  'ketot',
  'ketta',
  'keuls',
  'keurs',
  'keuse',
  'kewal',
  'kewel',
  'kewer',
  'kgadi',
  'kgami',
  'kgcal',
  'kgkal',
  'khadi',
  'khali',
  'kheis',
  'khmer',
  'khoin',
  'khoro',
  'kiaat',
  'kiang',
  'kibla',
  'kieke',
  'kieko',
  'kieks',
  'kiele',
  'kieme',
  'kieps',
  'kiesa',
  'kiese',
  'kiets',
  'kieue',
  'kiewe',
  'kieza',
  'kihon',
  'kilim',
  'kille',
  'kilos',
  'kilte',
  'kinds',
  'kinke',
  'kinna',
  'kinne',
  'kinol',
  'kinta',
  'kiosk',
  'kipwa',
  'kirts',
  'kiste',
  'kiswa',
  'kitse',
  'klaag',
  'klaar',
  'klaas',
  'klaer',
  'klaks',
  'klamp',
  'klang',
  'klank',
  'klant',
  'klaps',
  'klare',
  'klase',
  'klats',
  'klede',
  'kledy',
  'kleed',
  'kleef',
  'kleen',
  'kleer',
  'kleet',
  'kleim',
  'klein',
  'klere',
  'klerk',
  'klets',
  'kleur',
  'klewe',
  'klief',
  'kliek',
  'klier',
  'kliks',
  'kline',
  'kling',
  'klink',
  'klips',
  'klise',
  'klits',
  'kloek',
  'klomp',
  'klone',
  'klong',
  'klonk',
  'klont',
  'kloof',
  'kloon',
  'kloot',
  'klops',
  'klote',
  'klots',
  'kloue',
  'klowe',
  'klubs',
  'kluif',
  'kluis',
  'kluit',
  'kluts',
  'kluwe',
  'kmdmt',
  'knaag',
  'knaak',
  'knaap',
  'knaer',
  'knaks',
  'knape',
  'knaps',
  'knars',
  'kneep',
  'knegs',
  'knerp',
  'kners',
  'kneus',
  'kniee',
  'knieg',
  'kniel',
  'knies',
  'kniks',
  'knods',
  'knoei',
  'knoes',
  'knoet',
  'knooi',
  'knook',
  'knoop',
  'knoor',
  'knope',
  'knore',
  'knors',
  'knots',
  'knoue',
  'knuis',
  'knype',
  'koala',
  'koati',
  'koban',
  'kobas',
  'kobba',
  'kobos',
  'kobra',
  'kodes',
  'kodon',
  'koeel',
  'koeie',
  'koeis',
  'koeka',
  'koeke',
  'koele',
  'koema',
  'koena',
  'koeng',
  'koepa',
  'koera',
  'koerd',
  'koerk',
  'koers',
  'koert',
  'koesa',
  'koets',
  'kofia',
  'kofta',
  'kogel',
  'kojan',
  'kojin',
  'kokar',
  'koken',
  'koker',
  'koket',
  'kokke',
  'kokki',
  'kokon',
  'kokos',
  'koksa',
  'kokus',
  'kolby',
  'kolel',
  'kolie',
  'kolke',
  'kolle',
  'kolom',
  'kolon',
  'kolos',
  'kolot',
  'kolwe',
  'kolyk',
  'komaf',
  'koman',
  'komas',
  'kombi',
  'kombo',
  'kombu',
  'komko',
  'komma',
  'komme',
  'kompo',
  'komyn',
  'konde',
  'konga',
  'kongo',
  'kongs',
  'konka',
  'konks',
  'konto',
  'konus',
  'konyn',
  'kooks',
  'koord',
  'koors',
  'koort',
  'koots',
  'kopaf',
  'kopal',
  'kopek',
  'koper',
  'kopie',
  'kopla',
  'koppe',
  'kopra',
  'kopry',
  'koran',
  'korea',
  'koret',
  'kornu',
  'korog',
  'korps',
  'korra',
  'korte',
  'korts',
  'korwe',
  'kosak',
  'kosec',
  'kosek',
  'kosse',
  'kosta',
  'koste',
  'koswa',
  'kosyn',
  'kotta',
  'kouda',
  'kouer',
  'koups',
  'kousa',
  'kouse',
  'koute',
  'kowas',
  'kowel',
  'kraag',
  'kraai',
  'kraak',
  'kraal',
  'kraam',
  'kraan',
  'kraft',
  'krait',
  'krake',
  'krale',
  'krame',
  'kramp',
  'krane',
  'krank',
  'krans',
  'krant',
  'krats',
  'kreef',
  'kreek',
  'kreep',
  'kreet',
  'kreip',
  'kreng',
  'krenk',
  'krent',
  'kreta',
  'krete',
  'kreuk',
  'kreun',
  'krewe',
  'kriek',
  'kriel',
  'krimp',
  'kring',
  'krink',
  'krint',
  'kript',
  'krits',
  'kroee',
  'kroeg',
  'kroek',
  'kroep',
  'kroes',
  'krone',
  'kroom',
  'kroon',
  'kroos',
  'kruid',
  'kruie',
  'kruik',
  'kruim',
  'kruin',
  'kruip',
  'kruis',
  'kruit',
  'kruks',
  'kryge',
  'kubis',
  'kubit',
  'kubus',
  'kudde',
  'kudzu',
  'kugel',
  'kuier',
  'kuile',
  'kuipe',
  'kuise',
  'kuite',
  'kuiwe',
  'kukri',
  'kulot',
  'kumek',
  'kunde',
  'kunne',
  'kupel',
  'kupie',
  'kuras',
  'kuret',
  'kurie',
  'kurio',
  'kurke',
  'kurwe',
  'kusek',
  'kusse',
  'kuste',
  'kuvet',
  'kwaad',
  'kwaai',
  'kwaak',
  'kwaal',
  'kwade',
  'kwale',
  'kwalk',
  'kwant',
  'kwark',
  'kwart',
  'kwasi',
  'kweek',
  'kweel',
  'kween',
  'kwela',
  'kwene',
  'kwent',
  'kwets',
  'kwiek',
  'kwien',
  'kwilt',
  'kwink',
  'kwint',
  'kwits',
  'kwota',
  'kykee',
  'kyker',
  'kykie',
  'kywie',
  'laags',
  'laaie',
  'laans',
  'laars',
  'labia',
  'laere',
  'laers',
  'lager',
  'lagge',
  'lagie',
  'lagte',
  'lakei',
  'laken',
  'laker',
  'lakke',
  'lakse',
  'lamas',
  'lamba',
  'lamel',
  'lamia',
  'lamle',
  'lamme',
  'lampe',
  'lamte',
  'lande',
  'lange',
  'langs',
  'lanie',
  'lanse',
  'lapas',
  'lapel',
  'lapos',
  'lappe',
  'lapsi',
  'largo',
  'larie',
  'larwe',
  'laser',
  'lasie',
  'lasse',
  'lasso',
  'laste',
  'latei',
  'later',
  'latex',
  'latte',
  'latyn',
  'lauan',
  'laude',
  'lavas',
  'lawwe',
  'lazzo',
  'ledig',
  'leend',
  'leers',
  'leery',
  'leeus',
  'leges',
  'legio',
  'lehen',
  'leids',
  'leier',
  'lekka',
  'lelie',
  'lelik',
  'lelle',
  'lemma',
  'lemme',
  'lemur',
  'lende',
  'lener',
  'lenig',
  'lenin',
  'lenis',
  'lense',
  'lente',
  'lento',
  'lepel',
  'lepra',
  'lepus',
  'leser',
  'lesse',
  'leste',
  'letyd',
  'leuen',
  'leuse',
  'lewer',
  'lewes',
  'liaan',
  'liana',
  'liane',
  'libel',
  'libie',
  'lidar',
  'liebe',
  'liede',
  'liefs',
  'liere',
  'liese',
  'liewe',
  'ligas',
  'ligeg',
  'ligsy',
  'ligte',
  'limba',
  'limbo',
  'linde',
  'linie',
  'links',
  'linne',
  'linon',
  'linte',
  'lippe',
  'liras',
  'lisis',
  'lisse',
  'liste',
  'litam',
  'liteg',
  'liter',
  'litte',
  'livre',
  'llama',
  'lobbe',
  'locum',
  'locus',
  'loder',
  'loeka',
  'loens',
  'loepe',
  'loess',
  'lofui',
  'logen',
  'logge',
  'logos',
  'loket',
  'lokke',
  'lokus',
  'lompe',
  'longa',
  'longe',
  'lonte',
  'loods',
  'loops',
  'loper',
  'lopie',
  'lords',
  'loris',
  'losie',
  'losse',
  'lotos',
  'lotte',
  'lotto',
  'lotus',
  'louer',
  'louis',
  'loute',
  'lower',
  'ltkol',
  'luffa',
  'lugte',
  'luide',
  'luier',
  'luike',
  'luime',
  'luise',
  'luite',
  'lukas',
  'lumen',
  'luner',
  'lunse',
  'lupus',
  'lurex',
  'lusse',
  'luste',
  'luuks',
  'lycra',
  'lyder',
  'lydie',
  'lyers',
  'lyfie',
  'lyken',
  'lykwa',
  'lynas',
  'lynch',
  'lynig',
  'lysie',
  'lysol',
  'lyste',
  'lywal',
  'lywig',
  'maagd',
  'maand',
  'maars',
  'maart',
  'maats',
  'macho',
  'madam',
  'maere',
  'mafia',
  'magie',
  'magma',
  'magna',
  'magol',
  'magot',
  'magou',
  'magte',
  'magus',
  'mahdi',
  'mahem',
  'mahen',
  'majat',
  'makat',
  'makel',
  'maker',
  'maket',
  'makke',
  'makos',
  'makou',
  'makro',
  'maksi',
  'malby',
  'maler',
  'malfa',
  'malie',
  'malle',
  'malse',
  'malta',
  'malui',
  'malva',
  'mamba',
  'mambo',
  'mamma',
  'mampa',
  'manel',
  'maner',
  'manga',
  'mango',
  'mania',
  'manie',
  'manis',
  'manke',
  'manna',
  'manne',
  'manta',
  'manus',
  'maori',
  'marge',
  'maria',
  'marke',
  'marog',
  'marse',
  'marta',
  'masel',
  'maser',
  'massa',
  'masse',
  'maste',
  'matee',
  'mater',
  'mates',
  'matie',
  'matig',
  'matso',
  'matte',
  'matzo',
  'mauve',
  'mavas',
  'maxim',
  'mbeki',
  'mbila',
  'mbira',
  'mebos',
  'meder',
  'media',
  'meely',
  'meent',
  'meeue',
  'meide',
  'mekka',
  'melba',
  'melde',
  'melee',
  'meles',
  'melos',
  'memme',
  'menie',
  'menig',
  'mense',
  'merel',
  'merke',
  'meros',
  'meson',
  'messe',
  'meter',
  'methi',
  'metra',
  'metro',
  'mette',
  'meule',
  'mezzo',
  'miaau',
  'miami',
  'miang',
  'midas',
  'midde',
  'miede',
  'miere',
  'mieta',
  'miete',
  'miets',
  'mikro',
  'milde',
  'milli',
  'minag',
  'minim',
  'minne',
  'minus',
  'mioom',
  'mioop',
  'miose',
  'mirre',
  'mirte',
  'misel',
  'misry',
  'misse',
  'mites',
  'mitos',
  'model',
  'modem',
  'modes',
  'modul',
  'modus',
  'moede',
  'moeks',
  'moere',
  'moers',
  'moete',
  'moeti',
  'moets',
  'mogol',
  'mohel',
  'mohur',
  'moira',
  'moker',
  'mokka',
  'moler',
  'moles',
  'molik',
  'molla',
  'molle',
  'molog',
  'molwa',
  'monde',
  'monel',
  'mooie',
  'moois',
  'moord',
  'moors',
  'moose',
  'moppe',
  'morak',
  'mores',
  'morge',
  'morog',
  'moron',
  'morre',
  'morse',
  'moses',
  'mosie',
  'mosse',
  'motby',
  'motel',
  'motet',
  'motor',
  'motta',
  'motte',
  'motto',
  'movie',
  'mowwe',
  'msasa',
  'mufti',
  'muile',
  'muise',
  'mukus',
  'mukwa',
  'mungo',
  'munte',
  'murex',
  'musea',
  'muses',
  'musse',
  'musth',
  'muwwe',
  'mvuli',
  'mylar',
  'mymer',
  'myner',
  'myter',
  'naaap',
  'naais',
  'naakt',
  'naald',
  'naand',
  'naans',
  'nabba',
  'nabbo',
  'nably',
  'nabob',
  'nabot',
  'nabye',
  'nactu',
  'nadae',
  'nadag',
  'nadat',
  'nader',
  'nadir',
  'nadra',
  'naels',
  'naend',
  'naens',
  'nafie',
  'nafta',
  'nagee',
  'nagse',
  'nagte',
  'nagwa',
  'nahol',
  'nahou',
  'naief',
  'nakom',
  'nakos',
  'nakyk',
  'naman',
  'namas',
  'namib',
  'namie',
  'namma',
  'nampo',
  'nanag',
  'naoes',
  'naomi',
  'naoog',
  'napos',
  'nappe',
  'napyn',
  'naras',
  'narig',
  'naroe',
  'narra',
  'narre',
  'naruk',
  'nasie',
  'nasin',
  'nasit',
  'nasny',
  'natal',
  'natel',
  'natou',
  'natte',
  'naure',
  'navra',
  'navul',
  'navye',
  'nawas',
  'nawel',
  'nawin',
  'nawys',
  'nazis',
  'neder',
  'neefs',
  'nefie',
  'neger',
  'negus',
  'nekaf',
  'nekke',
  'nemer',
  'nenna',
  'nenne',
  'nenta',
  'nepal',
  'nerol',
  'nerts',
  'nerwe',
  'neste',
  'netel',
  'netso',
  'netsy',
  'nette',
  'netto',
  'neume',
  'neuro',
  'neuse',
  'neute',
  'nevus',
  'newel',
  'newwe',
  'nexus',
  'ngaka',
  'ngana',
  'nguni',
  'nidus',
  'nieks',
  'niere',
  'niese',
  'niets',
  'niger',
  'nigte',
  'nihil',
  'nikse',
  'nimfe',
  'nimro',
  'ninja',
  'ninon',
  'ninta',
  'nisan',
  'nisse',
  'nivea',
  'nivpn',
  'nizam',
  'njaap',
  'njaba',
  'njala',
  'nkone',
  'nkupe',
  'nobbo',
  'nobel',
  'nodal',
  'nodig',
  'nodus',
  'noeps',
  'nogal',
  'nokas',
  'nokke',
  'noksa',
  'nomen',
  'nones',
  'nonet',
  'nonna',
  'nonne',
  'nooie',
  'nooit',
  'noord',
  'noors',
  'noppe',
  'norka',
  'norme',
  'norra',
  'norre',
  'norro',
  'norse',
  'nosem',
  'nosie',
  'notas',
  'nouer',
  'nouja',
  'noute',
  'novas',
  'novum',
  'nozem',
  'nsais',
  'nsoar',
  'nudis',
  'nukke',
  'nulle',
  'nurks',
  'nusas',
  'nutte',
  'nuuts',
  'nuwer',
  'nuwes',
  'nwloi',
  'nydig',
  'nylon',
  'nywer',
  'nzasm',
  'oases',
  'objek',
  'obool',
  'odeem',
  'odeon',
  'odeur',
  'odium',
  'oefen',
  'oehoe',
  'oemie',
  'oempa',
  'oenie',
  'oerie',
  'oeros',
  'oerou',
  'oesaf',
  'oeste',
  'oewer',
  'oezoe',
  'offer',
  'ogief',
  'ogies',
  'ogpoe',
  'ojief',
  'okapi',
  'oksel',
  'oktet',
  'olien',
  'olies',
  'olywe',
  'omarm',
  'omber',
  'ombou',
  'ombud',
  'omdat',
  'omdig',
  'omdol',
  'omdop',
  'omdra',
  'omega',
  'omgee',
  'omhol',
  'omhou',
  'omhul',
  'omies',
  'omina',
  'omkap',
  'omkom',
  'omkry',
  'omkyk',
  'omlat',
  'omlei',
  'omlyn',
  'omlys',
  'ompad',
  'ompak',
  'ompas',
  'ompik',
  'omrol',
  'omruk',
  'omset',
  'omsig',
  'omsit',
  'omsny',
  'omtak',
  'omtas',
  'omtes',
  'omtol',
  'omval',
  'omvat',
  'omver',
  'omvou',
  'omwal',
  'omweg',
  'omwip',
  'onbep',
  'onder',
  'ondig',
  'oneer',
  'oneie',
  'onewe',
  'onfyn',
  'oniks',
  'onlus',
  'onmag',
  'onmin',
  'onnet',
  'onnie',
  'onnut',
  'onpas',
  'onreg',
  'onrus',
  'onryp',
  'onsag',
  'onses',
  'onsie',
  'onsin',
  'ontse',
  'ontug',
  'ontwy',
  'ontys',
  'onvas',
  'onvry',
  'onwel',
  'onwil',
  'onwis',
  'onwys',
  'oonde',
  'oople',
  'oopoe',
  'oopte',
  'oorde',
  'oorhe',
  'oorja',
  'oorle',
  'oorly',
  'oorry',
  'oorse',
  'oorsy',
  'ooryl',
  'oossy',
  'ooste',
  'opaak',
  'opaal',
  'opbel',
  'opbie',
  'opbly',
  'opbod',
  'opbol',
  'opbos',
  'opbou',
  'opdam',
  'opdat',
  'opdel',
  'opdis',
  'opdok',
  'opdop',
  'opdra',
  'opdro',
  'opeen',
  'opeet',
  'opeis',
  'opera',
  'operd',
  'opfok',
  'opgee',
  'ophap',
  'ophef',
  'ophel',
  'ophet',
  'ophop',
  'ophou',
  'ophys',
  'opium',
  'opkam',
  'opkap',
  'opkik',
  'opkom',
  'opkry',
  'opkyk',
  'oplae',
  'oplap',
  'oplei',
  'oplek',
  'oplet',
  'oplig',
  'oplos',
  'oplug',
  'oplui',
  'oplyn',
  'oppak',
  'oppas',
  'opper',
  'oppie',
  'oppik',
  'oppof',
  'oppor',
  'oppot',
  'opreg',
  'oprek',
  'oprig',
  'oprit',
  'oprol',
  'oprui',
  'opruk',
  'opryg',
  'oprys',
  'opset',
  'opsie',
  'opsig',
  'opsit',
  'opsny',
  'opsom',
  'opstu',
  'optel',
  'optof',
  'optog',
  'optol',
  'optop',
  'opval',
  'opvat',
  'opvee',
  'opvis',
  'opvou',
  'opvra',
  'opvul',
  'opwag',
  'opwal',
  'opwas',
  'opwek',
  'opwel',
  'opwen',
  'opwin',
  'opwip',
  'opwys',
  'oraal',
  'orale',
  'orals',
  'orang',
  'orden',
  'order',
  'ordes',
  'oreer',
  'orego',
  'orent',
  'orgie',
  'orige',
  'orion',
  'orkes',
  'orlon',
  'orrel',
  'osama',
  'oscar',
  'osena',
  'oskar',
  'oskop',
  'osoon',
  'ospol',
  'ossie',
  'osvel',
  'oties',
  'otjie',
  'otlik',
  'otter',
  'ottie',
  'oubie',
  'oubok',
  'oubos',
  'oubul',
  'oudae',
  'oudag',
  'oudak',
  'ouder',
  'oudit',
  'ouens',
  'ouere',
  'ouers',
  'ougat',
  'oukat',
  'oukei',
  'oukoe',
  'oukop',
  'oukos',
  'oulap',
  'oulik',
  'oulyf',
  'ouman',
  'oumas',
  'oumie',
  'ounag',
  'ouooi',
  'ouoom',
  'oupad',
  'oupas',
  'oupie',
  'ouram',
  'ousie',
  'ouste',
  'ousus',
  'outie',
  'outis',
  'outyd',
  'ouwyf',
  'ouwyn',
  'ovaal',
  'ovale',
  'overs',
  'overt',
  'ovslu',
  'ovsov',
  'oxide',
  'paaie',
  'paaps',
  'paarl',
  'pablo',
  'padaf',
  'padda',
  'padeg',
  'padie',
  'padop',
  'padre',
  'pagad',
  'paget',
  'pagte',
  'pakka',
  'pakke',
  'pakos',
  'pakte',
  'pakwa',
  'pakys',
  'paleo',
  'palet',
  'palms',
  'palpi',
  'pampa',
  'panda',
  'pande',
  'panga',
  'panie',
  'panne',
  'pante',
  'papel',
  'papie',
  'papil',
  'pappa',
  'pappe',
  'pareo',
  'paria',
  'parig',
  'parka',
  'parke',
  'parma',
  'parow',
  'parra',
  'parte',
  'party',
  'parys',
  'paseo',
  'pasga',
  'pasja',
  'pasop',
  'passe',
  'pasta',
  'pasty',
  'patat',
  'patee',
  'pater',
  'patio',
  'patos',
  'pedel',
  'peile',
  'peins',
  'peits',
  'pekan',
  'pekel',
  'pella',
  'pello',
  'pelol',
  'pelse',
  'pengo',
  'penis',
  'penne',
  'pense',
  'peper',
  'peppa',
  'perde',
  'perel',
  'perke',
  'perse',
  'perth',
  'pesig',
  'peske',
  'peste',
  'pesto',
  'peter',
  'petit',
  'petra',
  'pette',
  'peule',
  'phlox',
  'phyla',
  'piano',
  'picot',
  'pieke',
  'piele',
  // 'piemp',
  'pieng',
  'pienk',
  'piepe',
  'pieps',
  'piere',
  'pieta',
  'piets',
  'pikee',
  'piket',
  'pikke',
  'pikol',
  'pilaf',
  'pilau',
  'pille',
  'pilus',
  'pinas',
  'pingo',
  'pinna',
  'pinot',
  'pinte',
  'pioen',
  'pipet',
  'pique',
  'piton',
  'pitso',
  'pitta',
  'pitte',
  'pixel',
  'pizza',
  'plaag',
  'plaak',
  'plaas',
  'plaat',
  'plaer',
  'plaid',
  'plaks',
  'plank',
  'plano',
  'plant',
  'plaps',
  'plase',
  'plate',
  'plato',
  'plaza',
  'plebs',
  'pleeg',
  'pleet',
  'plein',
  'pleit',
  'pleks',
  'plemp',
  'pleng',
  'pleti',
  'plets',
  'plint',
  'ploee',
  'ploeg',
  'plomp',
  'plonk',
  'plons',
  'plooi',
  'plots',
  'pluim',
  'pluis',
  'pluto',
  'poeem',
  'poeet',
  'poefe',
  'poefs',
  'poega',
  'poela',
  'poele',
  'poema',
  'poena',
  'poens',
  'poese',
  'poets',
  'poise',
  'poker',
  'pokke',
  'polei',
  'poler',
  'polio',
  'polis',
  'polje',
  'polka',
  'polla',
  'polle',
  'polse',
  'polvy',
  'polys',
  'pompe',
  'pomum',
  'ponde',
  'pondo',
  'ponie',
  'ponse',
  'ponte',
  'pools',
  'poort',
  'popel',
  'popes',
  'poppe',
  'porie',
  'porno',
  'porra',
  'porte',
  'porto',
  'poses',
  'posie',
  'poste',
  'poswa',
  'potas',
  'potch',
  'potig',
  'potte',
  'potto',
  'potwa',
  'pouse',
  'power',
  'praag',
  'praai',
  'praal',
  'praat',
  'prana',
  'prang',
  'prate',
  'prazo',
  'preek',
  'preer',
  'preie',
  'preke',
  'prent',
  'priem',
  'prima',
  'prins',
  'prion',
  'prior',
  'proef',
  'proes',
  'promp',
  'pronk',
  'pront',
  'prooi',
  'proos',
  'props',
  'prosa',
  'provo',
  'pruik',
  'pruil',
  'pruim',
  'pruis',
  'pruts',
  'pryse',
  'psalm',
  'psige',
  'psigo',
  'psoas',
  'ptose',
  'puber',
  'pubes',
  'pubis',
  'pukka',
  'pukke',
  'pulpa',
  'pulse',
  'punte',
  'pupil',
  'puree',
  'purim',
  'puris',
  'putte',
  'putti',
  'putto',
  'pwasa',
  'pyker',
  'pyler',
  'pynig',
  'pypas',
  'pyper',
  'pypie',
  'pyrex',
  'pyxis',
  'quasi',
  'queen',
  'queer',
  'queue',
  'quits',
  'raars',
  'rabas',
  'rabat',
  'rabbi',
  'radar',
  'radio',
  'radja',
  'radon',
  'radys',
  'rafel',
  'ragis',
  'ragoe',
  'raita',
  'rakel',
  'raket',
  'rakke',
  'ralle',
  'rally',
  'ramas',
  'ramba',
  'ramie',
  'ramme',
  'rampe',
  'rampi',
  'ramus',
  'ranch',
  'rande',
  'range',
  'ranke',
  'ranok',
  'rante',
  'rapat',
  'raper',
  'rapse',
  'raseg',
  'raser',
  'rasie',
  'raspe',
  'rasse',
  'rasta',
  'ratel',
  'ratib',
  'ratio',
  'ratse',
  'ratte',
  'ratyn',
  'raver',
  'ravot',
  'ravyn',
  'rayon',
  'reaal',
  'reale',
  'rebas',
  'rebat',
  'rebel',
  'rebus',
  'recce',
  'recto',
  'redde',
  'reder',
  'redes',
  'reeds',
  'reeel',
  'reeks',
  'reels',
  'reens',
  'reent',
  'regaf',
  'regby',
  'regie',
  'regio',
  'regle',
  'regop',
  'regse',
  'regte',
  'reier',
  'reine',
  'reise',
  'rekas',
  'reken',
  'rekke',
  'relik',
  'remas',
  'remme',
  'remwa',
  'renne',
  'rente',
  'repel',
  'repie',
  'rerig',
  'resep',
  'reses',
  'resin',
  'reste',
  'resus',
  'retor',
  'retro',
  'reuke',
  'reune',
  'reuns',
  'reuse',
  'revue',
  'rewer',
  'ribbe',
  'riele',
  'rieme',
  'riepa',
  'riete',
  'rieti',
  'rigor',
  'rikka',
  'ringe',
  'rioel',
  'riole',
  'riool',
  'risso',
  'rites',
  'ritme',
  'ritse',
  'ritte',
  'ritus',
  'riwwe',
  'riyal',
  'robbe',
  'robot',
  'robyn',
  'rodel',
  'rodeo',
  'roede',
  'roeie',
  'roeke',
  'roepe',
  'roere',
  'roers',
  'roesa',
  'roete',
  'roewe',
  'rofie',
  'rogge',
  'roker',
  'roket',
  'rokie',
  'rokig',
  'rokke',
  'roleg',
  'rolle',
  'rolwa',
  'roman',
  'romeo',
  'romer',
  'romig',
  'rompe',
  'ronde',
  'rondo',
  'roneo',
  'ronge',
  'rooie',
  'rooms',
  'roset',
  'rosie',
  'rosig',
  'rosse',
  'rosyn',
  'rotor',
  'rotse',
  'rotte',
  'rouer',
  'rouge',
  'rower',
  'rowwe',
  'rsfsr',
  'ruche',
  'ruens',
  'rûens',
  'rugby',
  'rugge',
  'rugsy',
  'ruier',
  'ruike',
  'ruile',
  'ruime',
  'ruine',
  'ruite',
  'rukke',
  'rukos',
  'rumba',
  'rumen',
  'rummy',
  'rupee',
  'rusie',
  'ruspe',
  'russe',
  'ruste',
  'ruweg',
  'ruwol',
  'ryers',
  'ryery',
  'rygat',
  'ryker',
  'rykes',
  'rykie',
  'ryles',
  'rylig',
  'rymel',
  'rymer',
  'rypad',
  'rypak',
  'ryper',
  'rypes',
  'ryreg',
  'ryrok',
  'rysie',
  'rysig',
  'rytyd',
  'ryweg',
  'saaau',
  'saaff',
  'saagu',
  'saals',
  'saans',
  'saawk',
  'sabba',
  'sabek',
  'sabel',
  'sabgv',
  'sablo',
  'sabra',
  'sabyn',
  'sacos',
  'sadag',
  'sadis',
  'sadou',
  'sadza',
  'saery',
  'saftu',
  'sagas',
  'sages',
  'sagie',
  'sagkn',
  'sagmj',
  'sagte',
  'sagtr',
  'saibi',
  'saiei',
  'saiga',
  'saigr',
  'saiia',
  'saiib',
  'saili',
  'saimn',
  'saipa',
  'saipb',
  'saisi',
  'sajet',
  'sakbk',
  'sakbr',
  'sakhm',
  'sakie',
  'sakke',
  'sakkn',
  'sakmp',
  'saksv',
  'sakva',
  'saldo',
  'salep',
  'salga',
  'salie',
  'salig',
  'salms',
  'salon',
  'salot',
  'salsa',
  'salto',
  'salvk',
  'salvo',
  'salwe',
  'salwo',
  'samba',
  'sambo',
  'samel',
  'samfa',
  'samie',
  'samma',
  'sampi',
  'samrd',
  'samro',
  'samwu',
  'samwv',
  'sanab',
  'sanae',
  'sanbd',
  'sanbi',
  'sanco',
  'sande',
  'sanef',
  'sanfa',
  'sanga',
  'sange',
  'sanik',
  'sanna',
  'sanok',
  'sanra',
  'sanrb',
  'sanrd',
  'sanso',
  'sansv',
  'santa',
  'santv',
  'sanwu',
  'sanza',
  'saoku',
  'saphv',
  'sappe',
  'sarag',
  'sarel',
  'sarie',
  'sarin',
  'saros',
  'sarpa',
  'sarpi',
  'sarvu',
  'sasar',
  'sasko',
  'sasol',
  'satan',
  'satay',
  'sateb',
  'sater',
  'satnt',
  'satsa',
  'satyn',
  'sauna',
  'saval',
  'savlu',
  'savmo',
  'savri',
  'savtl',
  'savto',
  'savuv',
  'savvy',
  'sawah',
  'sawel',
  'sawis',
  'sawit',
  'scala',
  'scene',
  'schwa',
  'scoop',
  'scrap',
  'scuba',
  'seboe',
  'sebra',
  'sebum',
  'sedan',
  'seder',
  'sedes',
  'sedig',
  'sedum',
  'seels',
  'seelt',
  'seeus',
  'seeys',
  'segen',
  'seges',
  'sehal',
  'seijy',
  'seile',
  'seine',
  'seise',
  'sekel',
  'seker',
  'sekse',
  'sekte',
  'sekum',
  'selas',
  'selde',
  'selei',
  'seles',
  'selfs',
  'selle',
  'selwe',
  'semel',
  'semen',
  'senit',
  'senna',
  'senor',
  'sense',
  'sente',
  'sepia',
  'sepie',
  'septa',
  'seraf',
  'serge',
  'serie',
  'serif',
  'serir',
  'serpe',
  'serum',
  'sesam',
  'sesde',
  'sesie',
  'sesse',
  'sesys',
  'setel',
  'sette',
  'seuna',
  'seuns',
  'seurs',
  'sevra',
  'sewes',
  'sfeen',
  'sfeer',
  'sfere',
  'sheba',
  'shire',
  'shiva',
  'shoah',
  'shona',
  'siaal',
  'siaan',
  'sibbe',
  'sibil',
  'sider',
  'sieal',
  'siejy',
  'sieke',
  'siele',
  'siels',
  'siend',
  'siene',
  'siens',
  'siesa',
  'sieso',
  'sieur',
  'sifon',
  'sigma',
  'signo',
  'sikhs',
  'sikli',
  'silfe',
  'silia',
  'silos',
  'sinai',
  'sinds',
  'singe',
  'sinne',
  'sinus',
  'sirie',
  'sirih',
  'sirsa',
  'sisal',
  'siste',
  'sitar',
  'siter',
  'situs',
  'sivet',
  'siwet',
  'siwwe',
  'sjaal',
  'sjahs',
  'sjako',
  'sjeef',
  'sjees',
  'sjefs',
  'sjeik',
  'sjiek',
  'sjiep',
  'sjiva',
  'sjiwa',
  'sjona',
  'sjunt',
  'sjuut',
  'skaad',
  'skaaf',
  'skaai',
  'skaak',
  'skaal',
  'skaam',
  'skaap',
  'skaar',
  'skade',
  'skadu',
  'skala',
  'skald',
  'skale',
  'skalk',
  'skame',
  'skans',
  'skape',
  'skare',
  'skarp',
  'skawe',
  'skede',
  'skeef',
  'skeel',
  'skeen',
  'skeep',
  'skeer',
  'skeet',
  'skeie',
  'skele',
  'skelm',
  'skema',
  'skend',
  'skene',
  'skenk',
  'skepe',
  'skerf',
  'skerm',
  'skerp',
  'skete',
  'skets',
  'skeur',
  'skeut',
  'skewe',
  // 'skiem',
  'skier',
  'skies',
  'skiet',
  'skild',
  'skimp',
  'skink',
  'skiry',
  'skist',
  'skith',
  'skoea',
  'skoei',
  'skoen',
  'skoep',
  'skole',
  'skone',
  'skons',
  'skoof',
  'skool',
  'skoon',
  'skoor',
  'skoot',
  'skore',
  'skorl',
  'skors',
  'skort',
  'skote',
  'skots',
  'skoue',
  'skout',
  'skrae',
  'skram',
  'skrap',
  'skree',
  'skrei',
  'skrif',
  'skrik',
  'skril',
  'skrip',
  'skrop',
  'skrot',
  'skrou',
  'skrum',
  'skryf',
  'skryn',
  'skuba',
  'skuif',
  'skuil',
  'skuim',
  'skuin',
  'skuit',
  'skuld',
  'skulp',
  'skure',
  'skurf',
  'skurk',
  'skuts',
  'skuur',
  'skuus',
  'skuwe',
  'skywe',
  'slaaf',
  'slaag',
  'slaai',
  'slaak',
  'slaan',
  'slaap',
  'slaat',
  'slams',
  'slang',
  'slank',
  'slape',
  'slawe',
  'sleep',
  'slegs',
  'sleng',
  'slenk',
  'sleur',
  'slier',
  'slink',
  'sloeg',
  'sloep',
  'sloer',
  'slons',
  'sloof',
  'sloom',
  'sloop',
  'sloot',
  'slope',
  'slote',
  'slowe',
  'sluik',
  'sluip',
  'sluis',
  'sluit',
  'slums',
  'slurp',
  'sluwe',
  'slyts',
  'smaad',
  'smaak',
  'smaal',
  'smake',
  'smalt',
  'smart',
  'smede',
  'smeed',
  'smeek',
  'smeer',
  'smelt',
  'smere',
  'smesj',
  'smeul',
  'smids',
  'smoel',
  'smoet',
  'smook',
  'smoor',
  'smous',
  'smout',
  'smuts',
  'snaak',
  'snaar',
  'snags',
  'snake',
  'snaps',
  'snare',
  'snars',
  'snede',
  'snees',
  'sneeu',
  'snerp',
  'sners',
  'snert',
  'snese',
  'snobs',
  'snode',
  'snoef',
  'snoei',
  'snoek',
  'snoep',
  'snoer',
  'snoet',
  'snood',
  'snork',
  'snuif',
  'snuit',
  'snyeg',
  'snyer',
  'soavo',
  'sober',
  'sodat',
  'sodie',
  'sodom',
  'sodra',
  'soeba',
  'soefi',
  'soeka',
  'soeke',
  'soeks',
  'soele',
  // 'soema',
  'soemo',
  'soene',
  'soepe',
  'soera',
  'soese',
  'soete',
  'soeto',
  'soets',
  'soewe',
  'sofas',
  'sofis',
  'softa',
  'sokke',
  'solas',
  'solat',
  'soldy',
  'soler',
  'solfa',
  'solis',
  'solum',
  'somar',
  'somas',
  'somer',
  'somma',
  'somme',
  'sonaf',
  'sonar',
  'sonde',
  'sones',
  'sonie',
  'sonne',
  'sonop',
  'sonsy',
  'sonte',
  'sonwa',
  'sooie',
  'soole',
  'soort',
  'sopas',
  'sopie',
  'soppe',
  'sorge',
  'sorre',
  'sorus',
  'sosio',
  'sosys',
  'soter',
  'sotho',
  'sotte',
  'sotto',
  'souci',
  'souse',
  'soute',
  'sover',
  'sowat',
  'sowel',
  'spaan',
  'spaar',
  'spaas',
  'spaat',
  'spade',
  'spalk',
  'spane',
  'spang',
  'spant',
  'spare',
  'spaza',
  'speek',
  'speel',
  'speen',
  'speer',
  'speke',
  'speld',
  'spele',
  'spelt',
  'spene',
  'spens',
  'spere',
  'sperm',
  'speul',
  'speur',
  'spied',
  // 'spieg',
  // 'spien',
  'spier',
  'spies',
  'spiff',
  'spils',
  'spina',
  'spint',
  'spion',
  'spits',
  'split',
  'splyt',
  'spoed',
  'spoeg',
  'spoel',
  'spoke',
  'spons',
  'spook',
  'spoor',
  'spore',
  'sport',
  'sprak',
  'spree',
  'sprei',
  'sprie',
  'sprot',
  'spuit',
  'spuls',
  'spuug',
  'spyse',
  'spyte',
  'spyts',
  'staaf',
  'staak',
  'staal',
  'staan',
  'staar',
  'staat',
  'stade',
  'stads',
  'stake',
  'stale',
  'stamp',
  'stand',
  'stane',
  'stang',
  'stank',
  'stans',
  'stase',
  'stasi',
  'state',
  'stawe',
  'steak',
  'stede',
  'steeg',
  'steek',
  'steel',
  'steen',
  'stege',
  'steil',
  'stein',
  'steke',
  'stele',
  'stelp',
  'stelt',
  'stene',
  'steng',
  'stent',
  'stere',
  'sterf',
  'sterk',
  'stern',
  'stert',
  'steun',
  'steur',
  'stewe',
  'stief',
  'stiek',
  'stier',
  'stilb',
  'stink',
  'stoas',
  'stoei',
  'stoel',
  'stoep',
  'stoer',
  'stoet',
  'stola',
  'stole',
  'stolp',
  'stols',
  'stoma',
  'stomp',
  'stond',
  'stone',
  'stonk',
  'stoof',
  'stook',
  'stool',
  'stoom',
  'stoor',
  'stoot',
  'store',
  'storm',
  'stort',
  'stote',
  // 'stouf',
  'stoup',
  'stout',
  'stowe',
  'straf',
  'strak',
  'stram',
  'strek',
  'strem',
  'strep',
  'stres',
  'stria',
  'strik',
  'strip',
  'strop',
  'strot',
  'strum',
  'stryd',
  'stryk',
  'stuif',
  'stuik',
  'stuip',
  'stuit',
  'stuks',
  'stulp',
  'stuur',
  'style',
  'stylo',
  'stywe',
  'sucre',
  'suede',
  'sugte',
  'suide',
  'suier',
  'suile',
  'suite',
  'suiwe',
  'sujet',
  'sukke',
  'sulfa',
  'sulke',
  'sulks',
  'sulla',
  'sulle',
  'sumak',
  'summa',
  'sunny',
  'super',
  'supra',
  'surah',
  'sushi',
  'sussa',
  'susse',
  'swaab',
  'swaai',
  'swaan',
  'swaap',
  'swaar',
  'swael',
  'swaer',
  'swaks',
  'swalk',
  'swalp',
  'swalu',
  'swami',
  'swane',
  'swang',
  'swaou',
  'swape',
  'swapo',
  'sware',
  'swart',
  'swati',
  'swazi',
  'swede',
  'sweed',
  'sweef',
  'sweem',
  'sweep',
  'sweer',
  'sweet',
  'sweie',
  'sweis',
  'swelg',
  'swenk',
  'swepe',
  'swere',
  'swerf',
  'swerk',
  'swerm',
  'swets',
  'swewe',
  'swiep',
  'swier',
  'swiet',
  'swing',
  'swink',
  // 'swirl',
  'swoee',
  'swoeg',
  'swoel',
  'swyge',
  'swyne',
  'syaap',
  'syaar',
  'sybas',
  'sybok',
  'sybos',
  'sydak',
  'sydal',
  'sydek',
  'sydig',
  'syent',
  'syfer',
  'syger',
  'syhek',
  'sykar',
  'sykis',
  'sykos',
  'sylig',
  'sylob',
  'sylyn',
  'symde',
  'symot',
  'synde',
  'syner',
  'synet',
  'synse',
  'sypad',
  'sypel',
  'sypyp',
  'syrah',
  'syrok',
  'sysag',
  'sysak',
  'sysie',
  'sytak',
  'syweg',
  'taaie',
  'taals',
  'tabak',
  'tabel',
  'tablo',
  'taboe',
  'tabun',
  'tabyn',
  'tafel',
  'tafsy',
  'tagis',
  'tagma',
  'taiga',
  'takel',
  'takie',
  'takke',
  'talie',
  'talle',
  'talus',
  'tamat',
  'tamil',
  'tamme',
  'tampa',
  'tande',
  'tanga',
  'tange',
  'tango',
  // 'tanie',
  'tanig',
  'tanka',
  'tanna',
  'tanta',
  'tante',
  'tanto',
  'tapas',
  'tapir',
  'tappe',
  'tapyt',
  'tarok',
  'tarot',
  'tarra',
  'tarwe',
  'tasal',
  'tasse',
  'tatab',
  'tatas',
  'tater',
  // 'tatie',
  'tatoe',
  'tatta',
  'tauon',
  'taupe',
  'taxis',
  'teaal',
  'teder',
  'teels',
  'teelt',
  'teems',
  'teens',
  'teenw',
  'tefie',
  'tegno',
  'teien',
  'teken',
  'teler',
  'telge',
  'temas',
  'temer',
  'tempe',
  'tempi',
  'tempo',
  'tenar',
  'tenke',
  'tenks',
  'tensy',
  'tente',
  'tenue',
  'tepel',
  'tereg',
  'terge',
  'terme',
  'terra',
  'terro',
  'tersy',
  'terte',
  'terts',
  'terug',
  'tesal',
  'tesis',
  'tesla',
  'tesse',
  'testa',
  'tetra',
  'tette',
  'teuel',
  'tewel',
  'tgham',
  'tgrsa',
  'thaba',
  'thais',
  'thali',
  'thari',
  'thars',
  'themp',
  'theta',
  'thnei',
  'thoer',
  'thoth',
  'tiara',
  'tiber',
  'tibet',
  'tibia',
  'tieks',
  'tiema',
  'tiene',
  'tieng',
  'tiepa',
  'tiepe',
  'tiere',
  'tiers',
  'tiete',
  'tifon',
  'tifus',
  'tikke',
  'tilde',
  'timol',
  'timus',
  'tinea',
  'tinne',
  'tinte',
  'tipes',
  'tippe',
  'tiram',
  'tiran',
  'titan',
  'titel',
  'titer',
  'tjaaf',
  'tjaag',
  'tjalk',
  'tjank',
  'tjeks',
  'tjiep',
  'tjilp',
  'tjing',
  'tjint',
  'tjips',
  'tjirp',
  'tjirr',
  // 'tjoef',
  'tjoek',
  'tjoep',
  'tjoop',
  'tjops',
  'tobie',
  'toefa',
  'toeka',
  'toele',
  'toeoe',
  'toere',
  'toese',
  'toets',
  'toewe',
  'toewy',
  'toeys',
  'tofoe',
  'togas',
  'togry',
  'togte',
  'togwa',
  'token',
  'tokio',
  'tolke',
  'tolle',
  'tomas',
  'tombe',
  'tondo',
  'tonga',
  'tonge',
  'tonka',
  'tonne',
  'tonus',
  'tonyn',
  'tooms',
  'toorn',
  'toppa',
  'toppe',
  'toque',
  'torah',
  'torie',
  'torra',
  'torre',
  'torro',
  'torse',
  'torso',
  'torts',
  'torus',
  'totem',
  'totie',
  'totta',
  'tower',
  'traag',
  'traai',
  'traak',
  'traan',
  'traer',
  'trama',
  'trane',
  'trans',
  'trant',
  'trede',
  'treif',
  'treil',
  'trein',
  'treks',
  'trema',
  'trems',
  'trens',
  'treps',
  'treur',
  'trias',
  'tribe',
  'triek',
  'tries',
  'trips',
  'trits',
  'troef',
  'troei',
  'troep',
  'troje',
  'tromp',
  'trone',
  'tronk',
  'troon',
  'troop',
  'troos',
  'trope',
  'trots',
  'troue',
  'truie',
  'trust',
  'truuk',
  'tsaar',
  'tsare',
  'tsjeg',
  'tubas',
  'tuber',
  'tuine',
  'tuite',
  'tulle',
  'tulpe',
  'tumor',
  'tunas',
  'turbo',
  'turke',
  'turks',
  'turyn',
  'tutor',
  'tutsi',
  'tutti',
  'tutus',
  'tweed',
  'tydig',
  'tzaar',
  'uboot',
  'uhuru',
  'uiers',
  'uitdr',
  'uitdy',
  'uiter',
  'uitja',
  'uitle',
  'uitry',
  'ukovs',
  'ulkus',
  'ultra',
  'umbra',
  'unibo',
  'uniek',
  'unies',
  'unisa',
  'unita',
  'uraan',
  'ureum',
  'urien',
  'urine',
  'uself',
  'uteri',
  'uvula',
  'vaars',
  'vaart',
  'vadem',
  'vader',
  'vagte',
  'vaker',
  'vakke',
  'valer',
  'vales',
  'valke',
  'valle',
  'valse',
  'vanaf',
  'vange',
  'vangs',
  'vanne',
  'varia',
  'varke',
  'varse',
  'varus',
  'vasal',
  'vasie',
  'vasle',
  'vasry',
  'vaste',
  'vasys',
  'vatte',
  'veels',
  'vegan',
  'vegwa',
  'veins',
  'velaf',
  'velde',
  'veler',
  'velle',
  'velum',
  'velyn',
  'venda',
  'vente',
  'venus',
  'venyn',
  'veraf',
  'verag',
  'veral',
  'veras',
  'verby',
  'verge',
  'verja',
  'verle',
  'verly',
  'vermy',
  'verre',
  'versa',
  'verse',
  'verso',
  'verte',
  'verwe',
  'veryl',
  'verys',
  'vesel',
  'veter',
  'vetes',
  'vetos',
  'vette',
  'video',
  'viets',
  'vilet',
  'villa',
  'vinde',
  'vinke',
  'vinne',
  'vinte',
  'viola',
  'viole',
  'viool',
  'vires',
  'virus',
  'visie',
  'visse',
  'vista',
  'visum',
  'vitae',
  'vitro',
  'vlaag',
  'vlees',
  'vleie',
  'vleis',
  'vlerk',
  'vliee',
  'vlieg',
  'vlies',
  'vliet',
  'vloed',
  'vloei',
  'vloek',
  'vloer',
  'vlooi',
  'vloot',
  'vlote',
  'vlyme',
  'vodde',
  'voege',
  'voegw',
  'voels',
  'voere',
  'voert',
  'voete',
  'volke',
  'volks',
  'volle',
  'volop',
  'vonds',
  'vonke',
  'vonte',
  'voort',
  'vorme',
  'vorms',
  'vosse',
  'votum',
  'vouer',
  'vraag',
  'vraat',
  'vraer',
  'vrank',
  'vrate',
  'vrede',
  'vrees',
  'vreet',
  'vrese',
  'vreug',
  'vries',
  'vrind',
  'vroed',
  'vroee',
  'vroeg',
  'vrome',
  'vroom',
  'vroue',
  'vryer',
  'vryes',
  'vryry',
  'vrywe',
  'vuile',
  'vulle',
  'vulva',
  'vurig',
  'vurke',
  'vyand',
  'vyfde',
  'vygie',
  'vyler',
  'vysel',
  'vywer',
  'waaie',
  'waard',
  'waens',
  'wafel',
  'wagte',
  'wakap',
  'waker',
  'wakis',
  'wakse',
  'walle',
  'walms',
  'walse',
  'wande',
  'wange',
  'wanne',
  'wants',
  'wapad',
  'wapen',
  'warme',
  'warts',
  'wasem',
  'wasig',
  'water',
  'watse',
  'watte',
  'watts',
  'wawyd',
  'webbe',
  'weber',
  'weder',
  'weens',
  'weger',
  'wegja',
  'wegle',
  'wegry',
  'weide',
  'weids',
  'weier',
  'weker',
  'wekie',
  'welaf',
  'welig',
  'welke',
  'welpe',
  'wemel',
  'wenas',
  'wende',
  'wenen',
  'wener',
  'wenke',
  'wenne',
  'wense',
  'werda',
  'werke',
  'werwe',
  'wesel',
  'wesie',
  'wespe',
  'weste',
  'wetsy',
  'wette',
  'wewer',
  'wiege',
  'wieke',
  'wiele',
  'wiere',
  'wigge',
  'wigte',
  'wilde',
  'wilds',
  'wilge',
  'wille',
  'winde',
  'wippe',
  'wisse',
  'wites',
  'witte',
  'wodka',
  'woede',
  'woema',
  'woeps',
  'woere',
  'woers',
  'wolke',
  'wolwe',
  'wonde',
  'woord',
  'worpe',
  'worse',
  'woude',
  'wraak',
  'wrake',
  'wrang',
  'wrede',
  'wreed',
  'wreef',
  'wreek',
  'wring',
  'wroeg',
  'wroet',
  'wrong',
  'wuiwe',
  'wulpe',
  'wulps',
  'wurms',
  'wydte',
  'wyfie',
  'wyser',
  'wyses',
  'wysie',
  'wysig',
  'xenon',
  'xerox',
  'xhosa',
  'ydele',
  'yking',
  'ylend',
  'yling',
  'ysbyl',
  'ysdek',
  'ysere',
  'ysige',
  'yskas',
  'yskis',
  'yslae',
  'yslik',
  'ysman',
  'yspak',
  'yspik',
  'yssak',
  'yssee',
  'ystee',
  'yster',
  'ystyd',
  'ysvos',
  'ysvry',
  'zaire',
  'zebra',

  // Kaaps
  'aaire',
  'abdas',
  'annes',
  'batja',
  'bilal',
  'boeja',
  'boeka',
  'bregh',
  'chais',
  'chill',
  'chise',
  'clora',
  'dadda',
  'dedda',
  'derra',
  'dolla',
  'draks',
  'eeste',
  'fieks',
  'ganja',
  'gatta',
  'gazie',
  'gejak',
  'ghaai',
  'goose',
  'grend',
  'gwaai',
  'gwaan',
  'habba',
  'ietse',
  'iftar',
  'jarre',
  'jeera',
  'laaik',
  'laain',
  'likes',
  'lines',
  'mamok',
  'march',
  'meisa',
  'mince',
  'ndoda',
  'nemma',
  'nwata',
  'ontie',
  'opbom',
  'orait',
  'permy',
  'piemp',
  'skiem',
  'soema',
  'spieg',
  'spien',
  'stouf',
  'swirl',
  'tanie',
  'tatie',
  'tjelt',
  'tjoef',
]
