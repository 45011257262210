export const WORDS = [
  'oulik',
  'kaart',
  'aarde',
  'gekom',
  'stomp',
  'koper',
  'kwaai',
  'stert',
  'oprig',
  'dosyn',
  'skuit',
  'darem',
  'slaag',
  'takel',
  'somer',
  'nasie',
  'vyfde',
  'eties',
  'pruim',
  'nogal',
  'hitte',
  'gereg',
  'ritme',
  'optel',
  'langs',
  'visie',
  'lente',
  'blaar',
  'braai',
  'troue',
  'swaar',
  'adres',
  'draad',
  'slang',
  'dwaal',
  'meter',
  'hulle',
  'reeds',
  'kopie',
  'hyser',
  'gesny',
  'ysige',
  'agter',
  'kamer',
  'onder',
  'stink',
  'vloek',
  'bekyk',
  'vroeg',
  'skrum',
  'graaf',
  'fluit',
  'omset',
  'opkom',
  'snert',
  'afsny',
  'klank',
  'stoel',
  'slaan',
  'gepas',
  'praat',
  'voete',
  'afvee',
  'skema',
  'fliek',
  'smart',
  'skeef',
  'bloot',
  'spoed',
  'staak',
  'trots',
  'vader',
  'geveg',
  'steek',
  'massa',
  'klein',
  'erger',
  'neefs',
  'klerk',
  'padda',
  'spits',
  'preek',
  'raket',
  'diens',
  'watte',
  'aksie',
  'roman',
  'nader',
  'titel',
  'weier',
  'yslik',
  'steel',
  'viool',
  'later',
  'graad',
  'weste',
  'kapel',
  'pronk',
  'kring',
  'gedig',
  'prent',
  'tensy',
  'halwe',
  'opsig',
  'gebou',
  'kreef',
  'kruis',
  'slaap',
  'tronk',
  'ander',
  'terug',
  'liewe',
  'staan',
  'insek',
  'tydig',
  'oplos',
  'skuim',
  'gedra',
  'reken',
  'water',
  'enkel',
  'bruid',
  'steen',
  'fleur',
  'vaart',
  'skoen',
  'spaar',
  'heers',
  'moles',
  'regop',
  'sleep',
  'staal',
  'datum',
  'geluk',
  'bruin',
  'skelm',
  'kraan',
  'spuit',
  'skuil',
  'afval',
  'besef',
  'nodig',
  'vlerk',
  'orkes',
  'sodat',
  'poort',
  'sneeu',
  'resep',
  'suide',
  'brood',
  'herfs',
  'dwing',
  'skaal',
  'lepel',
  'direk',
  'weens',
  'blink',
  'breek',
  'volop',
  'bedek',
  'gesin',
  'rugby',
  'tafel',
  'toets',
  'engel',
  'grond',
  'troos',
  'optog',
  'party',
  'leier',
  'speel',
  'erken',
  'sprei',
  'onsin',
  'oefen',
  'brein',
  'rafel',
  'smaak',
  'straf',
  'regie',
  'skewe',
  'peper',
  'steun',
  'skerm',
  'sterk',
  'emmer',
  'kluis',
  'dwerg',
  'brief',
  'beurs',
  'vlieg',
  'bloed',
  'skaap',
  'druip',
  'gedek',
  'beeld',
  'stuit',
  'broek',
  'kweek',
  'graag',
  'trein',
  'yster',
  'houer',
  'teken',
  'moord',
  'staat',
  'wraak',
  'stryk',
  'hemel',
  'sloot',
  'fiets',
  'oorde',
  'blaas',
  'groei',
  'erens',
  'hewig',
  'mense',
  'sebra',
  'links',
  'skool',
  'buite',
  'denke',
  'hamer',
  'wysig',
  'vermy',
  'opwek',
  'stoei',
  'verby',
  'drank',
  'tante',
  'veras',
  'beste',
  'orals',
  'plaas',
  'fluks',
  'tabel',
  'tempo',
  'gevul',
  'leser',
  'woord',
  'opval',
  'soort',
  'impak',
  'berei',
  'behou',
  'effek',
  'besem',
  'dronk',
  'skadu',
  'knoop',
  'graan',
  'amper',
  'troef',
  'nadat',
  'klink',
  'swaai',
  'sweef',
  'stoor',
  'wapen',
  'vloer',
  'baard',
  'sterf',
  'lewer',
  'storm',
  'skoot',
  'drang',
  'ooste',
  'stuur',
  'plein',
  'uniek',
  //  'gegee',
  'spoeg',
  'reeks',
  'kwart',
  'plaat',
  'skrik',
  'vrees',
  'ploeg',
  'naand',
  'smeer',
  'kwaad',
  'ketel',
  'flink',
  'sowat',
  'vyand',
  'sodra',
  //  'tande',
  //  'vroue',
  'geval',
  'hotel',
  'veral',
  'gesig',
  //  'ewige',
  'sport',
  'vanaf',
  'dieet',
  'einde',
  'blaai',
  'motor',
  'sesde',
  'albei',
  'sluit',
  //  'gevra',
  'onreg',
  'prins',
  'spier',
  'gebak',
  'berig',
  'skerp',
  //  'drome',
  'breed',
  'broer',
  'maart',
  'klaar',
  'humor',
  'omdat',
  'stoep',
  'vleis',
  'almal',
  //  'sewes',
  'power',
  'genot',
  'binne',
  'skryf',
  'derde',
  'bring',
  //  'gemis',
  'grens',
  'lelik',
  'draak',
  'maand',
  'vloot',
  'akker',
  'dosis',
  'yskas',
  'blits',
  'roete',
  'lysie',
  'groot',
  'skrap',
  'syfer',
  'skaam',
  'kloof',
  'smeek',
  'sowel',
  'nooit',
  'dwars',
  'gewas',
  'kruip',
  'brand',
  'stoot',
  'kleur',
  'vloei',
  'vlooi',
  'swart',
  'stoom',
  'dreig',
  'gelyk',
  'stryd',
  'beurt',
  //  'duime',
  'beter',
  'seker',
  'skets',
  'klomp',
  'spook',
  'ryker',
  'bydra',
  'vrede',
  'droom',
  'saans',
  'laken',
  'griep',
  'alles',
  'vraag',
  'kraak',
  'getal',
  //  'julie',
  'skuif',
  'groen',
  'appel',
  'kafee',
  'inkom',
  'glans',
  'skiet',
  'bewys',
  'arend',
  'slegs',
  //  'edele',
  'oplig',
  'insig',
  'swaer',
  'kabel',
  'stoof',
  'groet',
  'pienk',
  'stort',
  'besig',
  'slaai',
  'breuk',
  //  'goeie',
  'kroeg',
  'begin',
  'maagd',
  'gelei',
  'draer',
  'groep',
  'sagte',
  'kraag',
  'kroon',
  'leuen',
  'altyd',
  'spoor',
  //  'dooie',
  'tapyt',
  'bevel',
  'maker',
  'vloed',
  'ophou',
  'tabak',
  'buurt',
  'mango',
  'skuld',
  'mosie',
  'skoon',

  // repeat from here
  'oulik',
  'kaart',
  'aarde',
  'gekom',
  'stomp',
  'koper',
  'kwaai',
  'stert',
  'oprig',
  'dosyn',
  'skuit',
  'darem',
  'slaag',
  'takel',
  'somer',
  'nasie',
  'vyfde',
  'eties',
  'pruim',
  'nogal',
  'hitte',
  'gereg',
  'ritme',
  'optel',
  'langs',
  'visie',
  'lente',
  'blaar',
  'braai',
  'troue',
  'swaar',
  'adres',
  'draad',
  'slang',
  'dwaal',
  'meter',
  'hulle',
  'reeds',
  'kopie',
  'hyser',
  'gesny',
  'ysige',
  'agter',
  'kamer',
  'onder',
  'stink',
  'vloek',
  'bekyk',
  'vroeg',
  'skrum',
  'graaf',
  'fluit',
  'omset',
  'opkom',
  'snert',
  'afsny',
  'klank',
  'stoel',
  'slaan',
  'gepas',
  'praat',
  'voete',
  'afvee',

  'skrif',
  'veter',
  'virus',
  'bleek',
  'rower',
  'snork',
  'sweet',
  'vries',
  'sonde',
  'droog',
  'spoel',
  'konyn',
  'speld',
  'minus',
  'sluip',
  'spons',
  'smelt',
  'stamp',
  'bloei',
  'plant',
  'krimp',
  'piets',
  'slurp',
  'inent',
  'kuier',
  'model',
  'fokus',
  'kramp',
  'gogga',
  'komma',
  'denim',
  'flits',
  'gloei',
  'inbel',
  'goeie',
  'grawe',
  'drink',
  'dooie',
  'galop',
  'blind',
  'bonus',
  'afkyk',
  'broei',
  'boord',
  'alarm',

  // New list from Annika - added on 2023-07-29
  'bebos',
  'swerm',
  'enige',
  'rosie',
  'kakie',
  'gevee',
  'boeta',
  'meeue',
  'rosyn',
  'kyker',
  'strek',
  'hemde',
  'bynes',
  'omkap',
  'seuna',
  'romeo',
  'drein',
  'gebid',
  'duime',
  'inent',
  'nuuts',
  'media',
  'kanse',
  'opbly',
  'duiwe',
  'teels',
  'afdra',
  'bosse',
  'keuse',
  'gemis',
  'gerat',
  'gegee',
  'selle',
  'satan',
  'asman',
  'gelok',
  'spoel',
  'laaie',
  'sotho',
  'fakse',
  'sluip',
  'gawes',
  'bande',
  'opkap',
  'sytak',
  'tepel',
  'rande',
  'minus',
  'frons',
  'tulpe',
  'afrit',
  'gelag',
  'knaag',
  'oewer',
  'speen',
  'kusse',
  'omkom',
  'selfs',
  'smelt',
  'geheg',
  'taboe',
  'skone',
  'gekou',
  'wenke',
  'afeet',
  'tange',
  'webbe',
  'graat',
  'banke',
  'beige',
  'dooie',
  'afsit',
  'gemor',
  'fikse',
  'stamp',
  'angel',
  'gered',
  'reels',
  'gedog',
  'grawe',
  'boude',
  'trope',
  'kodes',
  'kroes',
  'stowe',
  'woema',
  'spens',
  'jeans',
  'niere',
  'bries',
  'robyn',
  'rokke',
  'serpe',
  'skeet',
  'tjank',
  'traan',
  'skote',
  'bokke',
  'bodem',
  'kolle',
  'ougat',
  'geite',
  'neste',
  'doepa',
  'aflui',
  'karpe',
  'boete',
  'notas',
  'bybel',
  'skurf',
  'agent',
  'vanne',
  'palms',
  'asems',
  'afkop',
  'soene',
  'rotse',
  'nekke',
  'feite',
  'inoes',
  'kloon',
  'vinne',
  'steak',
  'tipes',
  'invou',
  'skeel',
  'orige',
  'malva',
  'sweis',
  'kepse',
  'afpak',
  'omrol',
  'loods',
  'valse',
  'gloei',
  'vorms',
  'sasko',
  'brode',
  'wawyd',
  'galop',
  'larwe',
  'tjoek',
  'afdop',
  'ryery',
  'koswa',
  'bleek',
  'opdam',
  'insny',
  'omgee',
  'manna',
  'store',
  'lagie',
  'kloek',
  'peste',
  'lyfie',
  'woude',
  'rampe',
  'gehol',
  'woeps',
  'ingee',
  'deler',
  'tjips',
  'disse',
  'fyner',
  'lamme',
  'opeet',
  'vosse',
  'kapot',
  'sweep',
  'lippe',
  'kloue',
  'pette',
  'pynig',
  'wolke',
  'voere',
  'fudge',
  'gerek',
  'rompe',
  'pakke',
  'aflek',
  'knaap',
  'ringe',
  'koppe',
  'alwyn',
  'asbes',
  'ranke',
  'trone',
  'panda',
  'agies',
  'ewige',
  'skrop',
  'spons',
  'tubas',
  'gloed',
  'borde',
  'insit',
  'insak',
  'blare',
  'gholf',
  'ligte',
  'mirre',
  'afkom',
  'olywe',
  'longe',
  'gekyk',
  'boere',
  'wiele',
  'polse',
  'oplui',
  'stede',
  'gelol',
  'invee',
  'viole',
  'plaag',
  'balle',
  'skare',
  'bravo',
  'velle',
  'wyser',
  'rerig',
  'gasse',
  'gelas',
  'gegly',
  'aflag',
  'invul',
  'saals',
  'offer',
  'broei',
  'vygie',
  'skuus',
  'sesse',
  'afgee',
  'uiers',
  'baber',
  'aster',
  'monde',
  'klaas',
  'plank',
  'super',
  'vraat',
  'klere',
  'bekke',
  'stene',
  'perde',
  'draai',
  'jaers',
  'modem',
  'pappa',
  'miere',
  'patat',
  'prooi',
  'witte',
  'asook',
  'bolip',
  'boewe',
  'eland',
  'gepla',
  'afbly',
  'komma',
  'toeys',
  'meule',
  'inkry',
  'souse',
  'gebed',
  'vangs',
  'gekam',
  'kudde',
  'opkam',
  'mates',
  'blind',
  'oplei',
  'peule',
  'virus',
  'inpak',
  'nagte',
  'films',
  'kapok',
  'fooie',
  'sange',
  'gaste',
  'rykes',
  'digte',
  'plate',
  'bysit',
  'dawid',
  'nefie',
  'sonop',
  'knope',
  'tonne',
  'happe',
  'omsit',
  'afdek',
  'gewen',
  'heupe',
  'dames',
  'lotto',
  'taaie',
  'fonds',
  'sweet',
  'pikke',
  'inlos',
  'stout',
  'tolle',
  'kykie',
  'smake',
  'gesak',
  'venus',
  'afwas',
  'lawwe',
  'helde',
  'wense',
  'vryer',
  'opdra',
  'afgly',
  'henne',
  'gepyl',
  'koors',
  'geglo',
  'salms',
  'snuif',
  'gewys',
  'hoede',
  'werke',
  'pleeg',
  'oppas',
  'gehok',
  'bleik',
  'magie',
  'drink',
  'plooi',
  'gehik',
  'snork',
  'dagga',
  'opsie',
  'wyses',
  'range',
  'vorme',
  'oplek',
  'spene',
  'xhosa',
  'slote',
  'bakke',
  'rukke',
  'nouer',
  'valer',
  'samie',
  'watse',
  'bloei',
  'skink',
  'geure',
  'inkap',
  'selde',
  'netso',
  'diewe',
  'afpyl',
  'biere',
  'lappe',
  'buise',
  'mamma',
  'swere',
  'sybok',
  'kliek',
  'tjoep',
  'dwase',
  'visse',
  'arrie',
  'parte',
  'munte',
  'pouse',
  'geler',
  'trane',
  'omkyk',
  'kroek',
  'dorpe',
  'oplet',
  'swaap',
  'omver',
  'elfde',
  'opvat',
  'oonde',
  'opera',
  'neuse',
  'plaak',
  'apart',
  'gekla',
  'foute',
  'tiete',
  'spies',
  'doeks',
  'wesie',
  'ponie',
  'dowes',
  'busse',
  'stook',
  'staar',
  'ogies',
  'beset',
  'wolwe',
  'miaau',
  'klits',
  'wurms',
  'rakke',
  'domme',
  'gejag',
  'sweer',
  'japon',
  'sonde',
  'vette',
  'opsny',
  'wekie',
  'sysag',
  'model',
  'syrok',
  'oppof',
  'flous',
  'winde',
  'ryper',
  'mooie',
  'walle',
  'afkyk',
  'wange',
  'afkap',
  'siele',
  'oudag',
  'krone',
  'dowwe',
  'bolyf',
  'remme',
  'sepie',
  'sinus',
  'haaie',
  'lener',
  'arena',
  'seile',
  'ronde',
  'koord',
  'hokke',
  'wapad',
  'frats',
  'nukke',
  'dreun',
  'agste',
  'veter',
  'wegry',
  'gelek',
  'inval',
  'radio',
  'wasem',
  'duike',
  'drake',
  'eiers',
  'gewip',
  'skeer',
  'oupie',
  'snags',
  'gekap',
  'hekel',
  'aflig',
  'geles',
  'skeur',
  'lopie',
  'bende',
  'regte',
  'tjops',
  'spane',
  'opgee',
  'stief',
  'vroue',
  'bykom',
  'aflei',
  'bloos',
  'boeie',
  'hoeke',
  'doele',
  'gewig',
  'verwe',
  'pitte',
  'panne',
  'kywie',
  'sappe',
  'takke',
  'tutus',
  'armes',
  'mamba',
  'daisy',
  'kokon',
  'jinne',
  'wonde',
  'tunas',
  'grote',
  'worse',
  'plons',
  'passe',
  'nonne',
  'hakie',
  'lande',
  'afdak',
  'pruik',
  'doods',
  'kouer',
  'ompad',
  'skies',
  'opbou',
  'intik',
  'rofie',
  'messe',
  'onnie',
  'orrel',
  'tande',
  'aitsa',
  'repie',
  'aftik',
  'items',
  'swane',
  'tatta',
  'uitry',
  'tenks',
  'wreed',
  'video',
  'afwag',
  'varse',
  'gevry',
  'rooie',
  'grate',
  'asgat',
  'gekry',
  'etter',
  'kerke',
  'omlyn',
  'satyn',
  'omkry',
  'tuite',
  'pasta',
  'muise',
  'ritte',
  'skepe',
  'opwas',
  'knape',
  'april',
  'ivoor',
  'toppe',
  'roker',
  'tiene',
  'hopie',
  'lelie',
  'vonke',
  'narre',
  'kuier',
  'briek',
  'afhap',
  'kleef',
  'kriek',
  'pypie',
  'armer',
  'plase',
  'krane',
  'knieg',
  'smere',
  'bewer',
  'tuine',
  'speke',
  'indra',
  'hater',
  'stres',
  'sagie',
  'bedel',
  'apies',
  'afvry',
  'oppak',
  'potte',
  'snare',
  'paaie',
  'rowwe',
  'triek',
  'skons',
  'besie',
  'papie',
  'oeste',
  'fraai',
  'kolom',
  'getel',
  'afrig',
  'diere',
  'gewek',
  'ruite',
  'kasie',
  'sente',
  'wippe',
  'koeie',
  'afsak',
  'kiste',
  'vleie',
  'plonk',
  'basie',
  'ryers',
  'koala',
  'terte',
  'poppe',
  'gejas',
  'kampe',
  'haker',
  'enorm',
  'eende',
  'skrif',
  'junie',
  'musse',
  'kramp',
  'tiara',
  'lange',
  'snoet',
  'besit',
  'slurp',
  'gehou',
  'aftel',
  'sakie',
  'ruspe',
  'julle',
  'gemik',
  'drama',
  'kwale',
  'riete',
  'yspak',
  'kuite',
  'gepak',
  'skywe',
  'stote',
  'konyn',
  'hawes',
  'spoke',
  'afbuk',
  'nonna',
  'blond',
  'tjirp',
  'swaan',
  'sinne',
  'olies',
  'kakao',
  'liter',
  'kneus',
  'slape',
  'eters',
  'vales',
  'sjuut',
  'gewei',
  'gouer',
  'troon',
  'modes',
  'kelke',
  'sover',
  'torre',
  'maats',
  'klong',
  'karee',
  'glips',
  'dwelm',
  'traak',
  'skale',
  'gevra',
  'strik',
  'gelig',
  'krake',
  'waens',
  'enjin',
  'gerus',
  'neute',
  'gebyt',
  'vakke',
  'sakke',
  'seine',
  'dawie',
  'tiepe',
  'ystee',
  'otjie',
  'gogga',
  'gepik',
  'anker',
  'jasse',
  'blase',
  'beman',
  'flits',
  'robot',
  'boeke',
  'robbe',
  'otter',
  'gonna',
  'doppe',
  'rusie',
  'omdra',
  'zebra',
  'alarm',
  'skurk',
  'skade',
  'kuste',
  'tiere',
  'goete',
  'seuns',
  'adder',
  'vurke',
  'omvat',
  'gedag',
  'lusse',
  'gehop',
  'kante',
  'oupas',
  'geute',
  'kenne',
  'julie',
  'hekke',
  'koeke',
  'wydte',
  'oprol',
  'aande',
  'gesit',
  'gekas',
  'oumas',
  'bytel',
  'japan',
  'album',
  'gebel',
  'dwaas',
  'kolwe',
  'skure',
  'oopte',
  'noord',
  'askar',
  'emoji',
  'nulle',
  'gekos',
  'varke',
  'slaaf',
  'derms',
  'naels',
  'ovaal',
  'kouse',
  'danse',
  'braaf',
  'laser',
  'afbyt',
  'wilde',
  'skuur',
  'somme',
  'rower',
  'katte',
  'kwaak',
  'ouers',
  'inbly',
  'nerwe',
  'vrate',
  'klone',
  'oppik',
  'kreun',
  'ousus',
  'afdae',
  'doper',
  'opwen',
  'piets',
  'wyfie',
  'wafel',
  'salig',
  'egter',
  'inryg',
  'natal',
  'deken',
  'etery',
  'kobra',
  'karre',
  'hekse',
  'afrol',
  'aflos',
  'afkry',
  'pense',
  'soeke',
  'skaak',
  'kraal',
  'kwade',
  'babas',
  'hande',
  'skree',
  'ritse',
  'vaker',
  'truie',
  'vliee',
  'inhou',
  'klont',
  'ysman',
  'aanry',
  'front',
  'speld',
  'gleuf',
  'opsit',
  'soete',
  'dikte',
  'turbo',
  'kroos',
  'ganse',
  'antie',
  'yssak',
  'hoera',
  'oprit',
  'volke',
  'poste',
  'gryse',
  'onnet',
  'soute',
  'vasry',
  'asjas',
  'gevou',
  'kokke',
  'pokke',
  'gelid',
  'inlei',
  'preke',
  'afvat',
  'ghong',
  'kosse',
  'omvou',
  'opkyk',
  'vreet',
  'wrede',
  'kniee',
  'wette',
  'drade',
  'vries',
  'sjefs',
  'reier',
  'vrese',
  'gebly',
  'inhok',
  'doeke',
  'getik',
  'smous',
  'tower',
  'moeks',
  'plant',
  'ninja',
  'naald',
  'goeie',
  'hallo',
  'snaar',
  'vinke',
  'geken',
  'skole',
  'steke',
  'skenk',
  'masel',
  'gelos',
  'klets',
  'salon',
  'oumie',
  'bokse',
  'hoewe',
  'pizza',
  'wysie',
  'rotte',
  'sonne',
  'gerei',
  'china',
  'ouens',
  'kepie',
  'gehad',
  'intap',
  'valke',
  'dower',
  'hutte',
  'gejok',
  'asbak',
  'duine',
  'lenig',
  'gebuk',
  'onvas',
  'pille',
  'eelte',
  'kraai',
  'radys',
  'bulle',
  'kewer',
  'dakke',
  'kruie',
  'donse',
  'bloue',
  'tatoe',
  'gemak',
  'balke',
  'pasop',
  'reuse',
  'skulp',
  'ratel',
  'inpas',
  'glase',
  'afdag',
  'husse',
  'kombi',
  'hasie',
  'nuwes',
  'ramme',
  'opvou',
  'ryles',
  'gedip',
  'beker',
  'idees',
  'treur',
  'opvee',
  'spaan',
  'tjiep',
  'salie',
  'sasol',
  'sieke',
  'opeis',
  'tasse',
  'snoek',
  'halte',
  'sotte',
  'skeep',
  'dasse',
  'leeus',
  'omval',
  'droog',
  'parys',
  'klier',
  'inbel',
  'opset',
  'honde',
  'ratte',
]
