import { af } from 'date-fns/locale'

export const MAX_CHALLENGES = 6
export const ALERT_TIME_MS = 2000
export const LONG_ALERT_TIME_MS = 10000
export const REVEAL_TIME_MS = 350
export const WELCOME_INFO_MODAL_MS = 350
export const DISCOURAGE_INAPP_BROWSERS = true
export const ENABLE_MIGRATE_STATS = true
export const BLOWFISH_KEY = 'xbG!28wBWzZ&z8Ne9%Ysuf*LEpdr8#'
export const BLOWFISH_IV = 'EY5AUfx$'
export const ENABLE_ARCHIVED_GAMES = true
export const DATE_LOCALE = af
export const WELCOME_CREDITS_MODAL_MS = 350
export const DAY_OF_WEEK_TO_SHOW_CREDITS = 1 // 0 = Sunday, 1 = Monday, etc.
