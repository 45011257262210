import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  appVersion: string
  handleClose: () => void
}

export const InfoModal = ({ isOpen, appVersion, handleClose }: Props) => {
  return (
    <BaseModal
      title="Hoe om te speel"
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Raai die woord binne 6 probeerslae. Na elke raaiskoot sal die teëls se
        kleur verander om aan te dui hoe akkuraat jou raaiskoot was.
      </p>

      <p className="mt-4 text-sm font-bold text-gray-500 dark:text-gray-300">
        ⚠️ Let wel: 🌱 Worteltjie se oplossing mag meervoude en woorde in die
        verlede tyd wees. Dit is 'n afwyking van 🥕 Wortel se benadering. Hou
        dit in gedagte met jou raaiskote.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="F"
          status="correct"
        />
        <Cell value="L" isCompleted={true} />
        <Cell value="I" isCompleted={true} />
        <Cell value="T" isCompleted={true} />
        <Cell value="S" isCompleted={true} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Die letter F is in die woord en in die regte plek.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="B" isCompleted={true} />
        <Cell value="E" isCompleted={true} />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="N"
          status="present"
        />
        <Cell value="D" isCompleted={true} />
        <Cell value="E" isCompleted={true} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Die letter N is in die woord, maar in die verkeerde plek.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="K" isCompleted={true} />
        <Cell isRevealing={true} isCompleted={true} value="R" status="absent" />
        <Cell value="A" isCompleted={true} />
        <Cell value="A" isCompleted={true} />
        <Cell value="K" isCompleted={true} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Die letter R is nêrens in die woord nie.
      </p>

      <p className="mt-6 text-xs text-gray-500 dark:text-gray-300">
        App version: {appVersion}
      </p>
    </BaseModal>
  )
}
